import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FriendsiesContract } from '../../contracts/Friendsies';
import { getGhostCloudAllowlist } from '../../state/user/userSlice';
import classes from '../MintWidget/MintWidget.module.css';

const web3Provider = new ethers.providers.AlchemyProvider(process.env.REACT_APP_NETWORK, process.env.REACT_APP_ALCHEMY_API_KEY);

const GhostCloud = ({ signer, connectHandler, disconnectHandler }) => {
  const [isMinting, setMinting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const ghostCloudAllowlistStatus = useSelector((state) => state.user.ghostCloudAllowlist);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getGhostCloudAllowlist(signer));
  // }, [dispatch, signer]);

  // const fetchMe = () => {
  //   dispatch(getGhostCloudAllowlist(signer));
  // };

  const mint = async () => {
    if (!ghostCloudAllowlistStatus) return;

    // console.log('attempting to mint...');
    setErrorMessage('');
    setMinting(true);

    let ghostCloudPriceInEth = (ghostCloudAllowlistStatus.amount * ghostCloudAllowlistStatus.price) / 1000;

    let tx;
    try {
      tx = await FriendsiesContract(signer).safeMintGhostCloud(
        ghostCloudAllowlistStatus.amount,
        ghostCloudAllowlistStatus.price,
        ghostCloudAllowlistStatus.proof,
        { value: ethers.utils.parseEther(`${ghostCloudPriceInEth}`) }
      );
    } catch (error) {
      setMinting(false);
      if (error.message.includes('execution reverted')) {
        toast.error(`${error.message?.replace('execution reverted: ', '').replace('MetaMask Tx Signature: ', '') ?? 'Minting failed!'}`);
      } else if (error.message.includes('insufficient funds')) {
        toast.error(`Insufficient funds for transaction`);
      }
      // setErrorMessage(error.message?.replace('execution reverted: ', '').replace('MetaMask Tx Signature: ', '') ?? 'Minting failed!');
      return;
    }

    try {
      await tx.wait();
      setMinting(false);
    } catch ({ error }) {
      setMinting(false);
      // setErrorMessage(error.message?.replace('execution reverted: ', '').replace('MetaMask Tx Signature: ', '') ?? 'Minting failed!');
      return;
    }

    setErrorMessage(null);
  };

  return (
    <div className="flex flex-col font-FredokaOne text-sm">
      <div className={`place-content-center items-center flex flex-col font-FredokaOne text-sm `}>
        {/* <div className={`place-content-center items-center flex flex-col -mb-4 font-FredokaOne text-sm `}> */}
        {/* <div className=" place-content-center items-center">
          <div className="p-2">
            <p className="text-center text-fbuilderGreen font-FredokaOne ">GHOST CLOUD</p>
          </div>
        </div> */}

        <div className="place-content-center items-center">
          <div className="p-2">
            {ghostCloudAllowlistStatus && (
              <>
                <p className="text-center text-fbuilderGreen font-FredokaOne align-middle leading-4 mb-2">
                  GHOST
                  <br /> CLOUD
                </p>
                <p className="text-center text-fbuilderGreen font-FredokaOne text-xs">{ghostCloudAllowlistStatus.amount} AVAILABLE</p>
                { ghostCloudAllowlistStatus && ghostCloudAllowlistStatus.price > 0 && (<p className="text-center text-fbuilderGreen font-FredokaOne text-xs">{ghostCloudAllowlistStatus.price / 1000} ETH each</p>)}
              </>
            )}
          </div>
        </div>

        {ghostCloudAllowlistStatus && (
          <button onClick={() => mint()} className={`${classes.mintButton} text-xs flex flex-row`}>
            {isMinting && (
              <svg className="animate-spin h-5 w-5 mr-3 text-white" viewBox="0 0 24 24">
                {/* <circle className="opacity-25" cx="12" cy="12" r="10" stroke="text-white" stroke-width="4"></circle> */}
                <path
                  className="opacity-75 text-white"
                  fill="#0dbd00"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
            {isMinting ? 'CLAIMING' : 'CLAIM'}
          </button>
        )}
      </div>
    </div>
  );
};

export default GhostCloud;
