import { forwardRef, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import DefaultImage from '../../assets/friendsie_default.png';
import { traitDisplayName } from '../sceneutils';
import SproutCategory from '../../assets/images/sprout-category.png';
import classes from './TraitSelector.module.css';
import { TraitCategories } from '../TraitCategory';
import RightArrow from '../../assets/images/Arrow_Icon_Right.png';
import LeftArrow from '../../assets/images/Arrow_Icon_Left.png';
import UpArrow from '../../assets/images/up-arrow.png';
import DownArrow from '../../assets/images/down-arrow.png';
import { useGLTF, useTexture } from '@react-three/drei';
import { useSelector } from 'react-redux';
import sgck from '../../assets/images/sgck.png' 

import {
  setAvailableAssets2,
  updateAvailableAssets2
} from '../../state/assets/assetsSlice';
import { getAssetsForCategory } from '../sceneutils';

const Index = ({ traitCategory, friendsieState, handleStateChange}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(null);

  const [internalTraitCategory, setInternalTraitCategory] = useState(null);
  const [internalBuilderState, setInternalBuilderState] = useState(null);
  const [internalFriendsieState, setInternalFriendsieState] = useState(null);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const traitsPerPage = isMobile ? 4 : 8;

  const userAuth = useSelector((state) => state.user.userAuthToken);
  const builderState = useSelector((state) => state.user.builderState);
  const hasSyncedAssets = useSelector((state) => state.assets.syncedAssets)

  const assetsForCategory = useSelector((state) => {
    return getAssetsForCategory(state.assets.assets, traitCategory, friendsieState)
  });

  // set previous page of options
  const showPreviousEight = () => {
    if (currentPage === 0) {
      return;
    } else {
      let newPage = currentPage - 1;
      setCurrentPage(currentPage - 1);
      let newOptions = assetsForCategory ? assetsForCategory.slice(newPage * traitsPerPage, newPage * traitsPerPage + traitsPerPage) : null;

      // preload new assets
      newOptions.forEach((trait) => {
        traitCategory === 'face' ? useTexture.preload(trait.asset_url) : useGLTF.preload(trait.asset_url);
      });
    }
  };

  // set next page of options
  const showNextEight = () => {
    if (!assetsForCategory) return null;

    if (currentPage === Math.floor((assetsForCategory.length - 1) / traitsPerPage)) {
      return;
    } else {
      let newPage = currentPage + 1;
      setCurrentPage(currentPage + 1);
      let newOptions = assetsForCategory ? assetsForCategory.slice(newPage * traitsPerPage, newPage * traitsPerPage + traitsPerPage) : null;

      // preload new assets
      newOptions.forEach((trait) => {
        traitCategory === 'face' ? useTexture.preload(trait.asset_url) : useGLTF.preload(trait.asset_url);
      });

      //   setTraitOptions(newOptions);
    }
  };

  const arrowClick = (direction) => {
    let firstAvailableIndex;
    let nextAvailableIndex;

    if (direction === 'left') {
      for (var i = assetsForCategory.length - 1; i >= 0; i--) {
        let v = assetsForCategory[i]
        if (firstAvailableIndex === undefined && currentIndex !== i && v.available) {
          firstAvailableIndex = i
        }

        if (nextAvailableIndex === undefined && i < currentIndex && v.available) {
          nextAvailableIndex = i
        }
      }
    } else {
      for (i = 0; i < assetsForCategory.length - 1; i++) {
        let v = assetsForCategory[i]
        if (firstAvailableIndex === undefined && currentIndex !== i && v.available) {
          firstAvailableIndex = i
        }

        if (nextAvailableIndex === undefined && i > currentIndex && v.available) {
          nextAvailableIndex = i
        }
      }
    }

    let newIndex = nextAvailableIndex || firstAvailableIndex;
    handleStateChange(assetsForCategory[newIndex]);
    setCurrentIndex(newIndex);
  };

  useEffect(() => {
    if (!assetsForCategory) {
      return;
    }

    let itemIndex = friendsieState[traitCategory].id ? assetsForCategory.findIndex((obj) => obj.id === friendsieState[traitCategory].id) : 0; // friendsieState[traitCategory].id
    setCurrentIndex(itemIndex);

    // Await synchronization of all the available traits before refreshing the assets. 
    // If we don't await this, we get in weird rendering states. 
    if (builderState === 'building' && userAuth && !hasSyncedAssets) {
      return;
    }

    let startingPage = Math.floor(itemIndex / traitsPerPage);
    // setCurrentPage(startingPage);
    let options = assetsForCategory ? assetsForCategory.slice(startingPage * traitsPerPage, startingPage * traitsPerPage + traitsPerPage) : null; //  === 'Face' ? `Face${friendsieState.HeadColor}` : traitCategory;

    // preload new assets
    options.forEach((trait) => {
      traitCategory === 'face' ? useTexture.preload(trait.asset_url) : useGLTF.preload(trait.asset_url);
    });

    if (internalTraitCategory !== traitCategory || internalBuilderState !== builderState || internalFriendsieState !== friendsieState) {
      setInternalTraitCategory(traitCategory);
      setInternalBuilderState(builderState);
      setInternalFriendsieState(friendsieState);
      setCurrentPage(startingPage);
    }
  }, [traitCategory, traitsPerPage, friendsieState, assetsForCategory, internalTraitCategory, internalBuilderState, builderState, internalFriendsieState, userAuth, hasSyncedAssets]);

  let dividerLogic = assetsForCategory && assetsForCategory.slice(currentPage * traitsPerPage, currentPage * traitsPerPage + traitsPerPage).length;
  let traitCategorySlice = assetsForCategory && assetsForCategory.slice(currentPage * traitsPerPage, currentPage * traitsPerPage + traitsPerPage);

  return (
    <>
      {/* Left Arrow */}
      <div className={`fixed top-1/2 place-items-center left-6 md:left-1/4 lg:left-1/4 ${classes.leftArrow}`}>
        <div className="flex">
          <img
            draggable={false}
            onClick={() => arrowClick('left')}
            src={LeftArrow}
            alt="left arrow"
            className={`h-14 md:h-20 ${classes.arrowIcon} hover: cursor-pointer `}
          />
        </div>
      </div>

      {/* Right Arrow */}
      <div className={`fixed top-1/2 place-items-center right-6 md:right-1/4 lg:right-1/4 ${classes.rightArrow}`}>
        <div className="flex">
          <img
            draggable={false}
            onClick={() => arrowClick('right')}
            src={RightArrow}
            alt="right arrow"
            className={`h-14 md:h-20 ${classes.arrowIcon} hover: cursor-pointer`}
          />
        </div>
      </div>

      {/* Asset Browser */}
      <div className="m-1 md:w-11/12 fixed bottom-0 md:bottom-4 place-items-center inset-x-0 md:mx-auto md:m-0">
        <div className="flex place-content-center">
          <div className="w-full grid-rows-1 relative grid grid-cols-4 md:w-11/12 lg:w-1/2 lg:grid-rows-2 bg-fbluetrait md:border-t-4 md:border-b-4 md:border-l-4 md:border-faccentblue">
            {(dividerLogic && dividerLogic > 4) ? <div className="grid-divider" /> : null }

            {/* current category */}
            <div className="hidden md:block absolute -top-7 -left-1">
              <button className="friendsie-pill hover:cursor-default">
                <img
                  src={TraitCategories.find((o) => o.name === traitCategory).image}
                  alt="trait category"
                  className={`h-8 absolute -top-7 object-scale-down ${classes.categoryIcon}`}
                />
                {traitDisplayName(traitCategory).toUpperCase()}
              </button>
            </div>

            {/* Page counter */}
            <div className="hidden md:block absolute -top-7 -right-8 ">
              <button className="friendsie-pill hover:cursor-default">
                {`${assetsForCategory ? assetsForCategory.length : 0} ASSETS`}&nbsp;&nbsp;&nbsp;
                {assetsForCategory ? `${currentPage + 1}/${Math.ceil(assetsForCategory.length / traitsPerPage)}` : null}
              </button>
            </div>

            {/* Asset Options  */}
            {assetsForCategory &&
              assetsForCategory.slice(currentPage * traitsPerPage, currentPage * traitsPerPage + traitsPerPage).map((traitData, index) => {
                return (
                  <div
                    onClick={() => {
                      // dont update asset if its not available
                      if (traitData.available) {
                        handleStateChange(traitData);
                      }
                    }}
                    key={index}
                    className={`${gridLogic(index) ? 'grid-lines' : ''}  flex flex-col items-center  py-2 px-2 relative`}
                  >
                    <img
                      className={`${
                        traitData.available || (friendsieState[traitCategory].id && traitData.id === friendsieState[traitCategory].id)
                          ? `${classes.scaleOnHover} cursor-pointer`
                          : `${classes.grayScale} cursor-default`
                      } z-10 select-none object-scale-down h-8`}
                      alt="trait"
                      src={traitData.preview_url}
                    />
                    <p
                      className={`${
                        traitData.available || (friendsieState[traitCategory].id && traitData.id === friendsieState[traitCategory].id)
                          ? 'cursor-pointer text-faccentbluetext'
                          : 'cursor-default text-gray-400 opacity-50'
                      } z-10 font-builder leading-4 text-xs select-none text-center`}
                    >
                      {traitData.super_golden_cloud ? <span className='bg-fbuilderyellow rounded-xl px-1'>{`${formatTraitName(traitData.name)}`}</span> : formatTraitName(traitData.name)}{traitData.super_golden_cloud ? <img src={sgck} alt="super golden cloud key" className="relative md:absolute md:bottom-1 inline w-6 md:w-7"/> : null}
                    </p>
                    {/* Index 0 of current slice is "None" OR friendsie selected ID matches this boxes trait ID  */}
                    {(traitCategorySlice[index] && traitCategorySlice[index].name === 'None' && !friendsieState[traitCategory].id) ||
                    (friendsieState[traitCategory] && friendsieState[traitCategory].id && traitData.id === friendsieState[traitCategory].id) ? (
                      <div className={`absolute glowing-filled flex flex-col items-center `} />
                    ) : null}
                  </div>
                );
              })}
          </div>

          {/* Page Up/ Down Arrows */}
          <div className="bg-fbluetrait border-r-4 border-t-4 border-b-4 border-faccentblue">
            <button
              className={`h-3/6 block ${
                currentPage === 0 ? 'text-faccentblue cursor-default' : 'text-fdarkaccentblue cursor-pointer'
              } border-b-4 border-l-4 border-faccentblue font-bold select-none`}
              onClick={() => showPreviousEight()}
            >
              {currentPage !== 0 && <img src={UpArrow} alt="page down" className={`h-8 p-1 ${classes.scaleOnHover}`} />}
            </button>
            <button
              className="h-3/6 block text-fdarkaccentblue border-t-4 border-l-4  border-faccentblue  font-bold select-none"
              onClick={() => showNextEight()}
            >
              {assetsForCategory && assetsForCategory.length && currentPage < Math.floor(assetsForCategory.length / traitsPerPage) && (
                <img src={DownArrow} alt="page down" className={`h-8 p-1 ${classes.scaleOnHover}`} />
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;

const formatTraitName = (name) => {
  let formattedName = name.split(/(?=[A-Z])/);
  return formattedName.join(' ');
};

const gridLogic = (index) => {
  if (index === 0 || index === 4) {
    return false;
  } else {
    return true;
  }
};
