import React, { useRef, useMemo, memo } from 'react';
import { extend, useThree, useLoader, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { Water } from 'three/examples/jsm/objects/Water.js';
import waterNormalsJpg from '../../assets/textures/waterNormals.jpg';
import { MeshReflectorMaterial } from '@react-three/drei';
import { folder, useControls } from 'leva';
import { CylinderBufferGeometry } from 'three';

extend({ Water });

// https://codesandbox.io/s/react-three-fiber-water-shader-4qgn4?file=/src/index.js:1249-1260
const ReflectivePool = () => {
  // const { color } = useControls({
  //   DirectionalLightFront: folder({
  //     // dIntensityOne: { value: 0, min: 0, max: 10, step: 0.1 },
  //     // dIntensityOne: { value: 2.25, min: 0, max: 10, step: 0.1 },
  //     // dPositionOne: { value: [-0.5, 2.5, 5], min: -0.1, max: 10 },
  //     color: { value: '#505050', label: 'color' },
  //   }),
  // });
  return (
    <mesh dispose={null} rotation-x={-Math.PI / 2} position={[0, -0.02975, 0.02]}>
      <planeGeometry args={[0.4, 0.4]} />
      {/* <cylinderBufferGeometry args={[0.5, 0.5, 0.1, 64]} /> */}
      <MeshReflectorMaterial
        // blur={[100, 100]}
        // resolution={2048}
        // mixBlur={0.1}
        // mixStrength={0.7}
        // depthScale={2}
        // minDepthThreshold={2}
        // // color="#505050"
        // color="#00afc9"
        // metalness={1}
        // roughness={0.9}

        // blur={[100, 100]}
        // resolution={2048}
        // mixBlur={2}
        // mixStrength={1}
        // depthScale={5}
        // minDepthThreshold={7}
        // color={'#54e5ff'}
        // // color={'#1c8eff'}
        // metalness={1}
        // roughness={0}

        blur={[100, 100]}
        resolution={2048}
        mixBlur={0.25}
        mixStrength={0.8}
        depthScale={2}
        minDepthThreshold={0}
        color={'#54e5ff'}
        // color={'#1c8eff'}
        metalness={1}
        roughness={0.1}
      />
    </mesh>
  );
};

export default memo(ReflectivePool);

// const OldReflectivePool = () => {
//   const ref = useRef();
//   const gl = useThree((state) => state.gl);
//   const waterNormals = useLoader(THREE.TextureLoader, waterNormalsJpg);

//   waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping;
//   const geom = useMemo(() => new THREE.PlaneGeometry(0.5, 0.5), []);
//   const config = useMemo(
//     () => ({
//       textureWidth: 512,
//       textureHeight: 512,
//       waterNormals,
//       sunDirection: new THREE.Vector3(),
//       sunColor: 0xeb8934,
//       waterColor: 0x0064b5,
//       distortionScale: 0.005,
//       fog: true,
//       format: gl.encoding,
//       size: 0.1,
//     }),
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     [waterNormals]
//   );
//   useFrame((state, delta) => (ref.current.material.uniforms.time.value += delta / 10));
//   return <water ref={ref} args={[geom, config]} rotation-x={-Math.PI / 2} position={[0, -0.02, 0.02]} />;
// };
