import { memo, Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { PresentationControls, useProgress, OrbitControls, Loader, Environment, useTexture, useGLTF } from '@react-three/drei';
import * as THREE from 'three';
import FriendiseModel from './Friendsies/FriendiseModel';
import World from './Environment/World';
import TraitCategory from './TraitCategory';
import TraitSelector from './TraitSelector';
import { checkHeadFaceLogic, createModelFromUrl, createUrlFromState, randomizeFriendsie, statePreloader } from './sceneutils';
import data from '../data.json';
import axios from 'axios';
import { defaultApiData } from '../DEFAULTapiData';
import FrengenImg from '../assets/FrengenLogo1.png';
import { useMediaQuery } from 'react-responsive';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js';
import { fetchInitialData, setInitialDataFromWebsocket, undoSeed, updateSeeds } from '../api/api';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { toast } from 'react-toastify';
import EnterQueue from '../components/EnterQueue';
import classes from './FriendsieScene.module.css';
import LoadingCloudModel from './Friendsies/LoadingCloudModel';
import Mint from '../components/MintWidget';
import Fireflies from './Environment/Fireflies';
import RandomUndo from './RandomUndo';
import DebugTools from './DebugTools';

import FriendsWithYouCloud from '../assets/images/FriendsWithYouCloud.png';
import CongratulationsModal from './CongratulationsModal';
import {
  buildFriendsie,
  setBuildButtonLoading,
  setBuildingFriendsieLoadingState,
  setBuiltFriendsieImage,
  setDisableFinishBuild,
  setDisableUndoTimer,
  setFriendsieTokenId,
} from '../state/user/userSlice';
import ShareWidget from '../components/ShareWidget';
import BuilderModal from '../components/BuilderModal';
import Stats from '../components/Stats';
import CanvasComponent from './CanvasComponent';

import {
  setAssets,
  setAvailableAsset,
  setUnavailableAsset,
  setSyncedAssets
} from '../state/assets/assetsSlice';

// STATE TYPE
const initialState = {
  sprout: null,
  body: null,
  backpiece: null,
  accessory: null,
  shoe: null,
  head: null,
  headColor: null,
  headFixed: null,
  headSize: null,
  headNoSprout: null,
  fixedModel: null,
  face: null,
};

const mockObject = new THREE.Object3D();

const FriendsieBuilder = ({ signer, connectHandler, disconnectHandler }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const lookAtPos = new THREE.Vector3(0, isMobile ? 0.05 : 0.15, 0);

  // user information from redux
  const authToken = useSelector((state) => state.user.userAuthToken);
  const builderState = useSelector((state) => state.user.builderState);
  const buildingFriendsieLoadingState = useSelector((state) => state.user.buildingFriendsieLoadingState);
  const friendsieTokenId = useSelector((state) => state.user.friendsieTokenId);
  const disableUndoTimer = useSelector((state) => state.user.disableUndoTimer);

  const globalAssets = useSelector((state) => state.assets.assets)
  const hasSyncedAssets = useSelector((state) => state.assets.syncedAssets)

  // builder states
  const [friendsieState, setFriendsieState] = useState(null);
  const [previousFriendsieState, setPreviousFriendsieState] = useState(null);
  const [preloadedRandomState, setPreloadedRandomState] = useState(null);
  const [traitCategory, setTraitCategory] = useState('head');
  const [buildingFriendsieLoading, setBuildingFriendsieLoading] = useState(false);

  const [headAnimation, setHeadAnimation] = useState(null);
  const [sproutAnimation, setSproutAnimation] = useState(null);
  const [bodyAnimation, setBodyAnimation] = useState(null);
  const [accessoryAnimation, setAccessoryAnimation] = useState(null);
  const [shoeAnimation, setShoeAnimation] = useState(null);
  const [backpieceAnimation, setBackpieceAnimation] = useState(null);

  const [animationDuration, setAnimationDuration] = useState('none');

  const [mock, setMock] = useState(new THREE.Object3D());

  // websocket
  const [isWsConnected, setIsWsConnected] = useState(false);

  // URL state
  let [searchParams, setSearchParams] = useSearchParams();
  const [downloadingModel, setDownloadingModel] = useState(false);
  const [entireScene, setEntireScene] = useState(null);

  const [friendsieImage, setFriendsieImage] = useState(FrengenImg);

  // threejs scene states
  const { active, progress, loaded } = useProgress();

  const ws = useRef(null);
  const updateSeedsTimeoutRef = useRef(null);

  const dispatch = useDispatch();
  const store = useStore()


  // Inital app load data. if user is in builder, connect to websocket to get data
  useEffect(() => {
    const connectWebsocket = (builderState, authToken) => {
      if (builderState !== 'building' || !authToken) {
        console.log("Skipping connect websocket not building or not authed.");
        return;
      }

      if (isWsConnected) {
        console.log("Websocket already exists. Skipping.");
        return;
      }

      try {
        ws.current = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_URL}?token=${authToken}`);
        ws.current.onerror = (error) => {
          console.log('Unable to connect to websocket', error);
          toast.error('Unable to connect to server. Please ensure your adblocker is disabled!!');
        };
        ws.current.onopen = () => {
          setIsWsConnected(true);
        };
        ws.current.onclose = () => {
          setIsWsConnected(false);
          dispatch(setSyncedAssets(false))
          ws.current = null;
          console.log("Disconnected websocket.")
        };
        ws.current.onmessage = (e) => {
          processMessage(e)
        };
      } catch (error) {
        console.log('Unable to connect to websocket', error);
        toast.error('Unable to connect to server. Please ensure your adblocker is disabled!!');
      }
    };

    const processMessage = (e) => {
      let message = JSON.parse(e.data);
      if (!message || !message.kind || !message.data) {
        console.log("invalid websocket message. dropping.", message)
        return
      }

      if (message.kind === 'initial' && !message.data.assets) {
        console.log("invalid websocket message. no assets on initial message.", message)
        return
      }

      if (message.kind === 'initial') {
        dispatch(setAssets(message.data.assets))
        dispatch(setSyncedAssets(true))
      } else if (message.kind === 'available') {
        dispatch(setAvailableAsset(message.data))
      } else if (message.kind === 'unavailable') {
        dispatch(setUnavailableAsset(message.data))
      } else {
        console.log("Unhandled socket message: ", message)
      }
    };

    connectWebsocket(builderState, authToken);

    // When unrendered, cleanup websockets
    return () => {
      if (ws.current && isWsConnected) {
        ws.current.close();
        dispatch(setSyncedAssets(false))
      }
    };
  }, [dispatch, signer, builderState, authToken, isWsConnected]);


  // Inital app load data. if user is in preview, call this, and don't load data from websocket
  useEffect(() => {
    if (builderState === 'building') {
      return;
    }

    fetchInitialData(
      builderState,
      authToken,
      searchParams,
      setSearchParams,
      setFriendsieState,
      setPreloadedRandomState,
      setTraitCategory
    );
    // eslint-disable-next-line
  }, [builderState]);

  // User enters the builder, synchronize with websocket data.
  useEffect(() => {
    if (builderState !== 'building' || !authToken || !signer || !hasSyncedAssets) {
      return;
    }

    setInitialDataFromWebsocket(
      builderState,
      authToken,
      signer,
      searchParams,
      setSearchParams,
      setFriendsieState,
      setPreloadedRandomState,
      store.getState().assets.assets
    );

  }, [builderState, authToken, signer, hasSyncedAssets, store])


  const navigate = useNavigate();

  useEffect(() => {
    if (builderState === 'share' && friendsieTokenId) {
      navigate(`/share/${friendsieTokenId}?congrats=true`);
    }
  }, [builderState, friendsieTokenId, navigate]);

  // rate limit how often update seeds is called
  const debounceUpdateSeeds = useCallback(
    async (currentState, previousState, authToken, signer, builderState, newFriendsieState) => {
      let updateStatus = await updateSeeds(authToken, signer, builderState, newFriendsieState);
      if (!updateStatus) {
        setFriendsieState(currentState);
        setPreviousFriendsieState(previousState);
        // toast.error('Update failed. Options unavailable');
      }

      // update is done, re-enable build finish button
      dispatch(setDisableFinishBuild(false));
    },
    [dispatch]
  );

  /**
   * Update state to new friendsie selection. Change traits immediately and wait on API response for finality
   * @param traitData the new friendsie options
   */
  const handleStateChange = useCallback(
    async (traitData) => {
      // disable finish build button
      dispatch(setDisableFinishBuild(true));

      // temp hold seed - in case of race condition
      let previous = previousFriendsieState;
      let current = friendsieState;
      let newFriendsieState;

      setPreviousFriendsieState(friendsieState);

      // Check if sprout or face needs to change, as they rely on head traits
      if (traitCategory === 'head') {
        newFriendsieState = checkHeadFaceLogic(globalAssets, traitData, friendsieState);
        setFriendsieState(newFriendsieState);
        setSearchParams(createUrlFromState(newFriendsieState));
      }
      // user is not updating head, so no logic check needed
      else {
        newFriendsieState = {
          ...friendsieState,
          [traitCategory]: traitData.id ? traitData : '',
        };
        setFriendsieState(newFriendsieState);
        setSearchParams(createUrlFromState(newFriendsieState));
      }

      // if user is building for mint
      if (authToken && builderState === 'building') {
        // clear the previous timeout to throttle update seeds
        clearTimeout(updateSeedsTimeoutRef.current);
        updateSeedsTimeoutRef.current = setTimeout(() => debounceUpdateSeeds(current, previous, authToken, signer, builderState, newFriendsieState), 200);
      } else {
        dispatch(setDisableFinishBuild(false));
      }
    },
    [
      setFriendsieState,
      traitCategory,
      friendsieState,
      setSearchParams,
      authToken,
      builderState,
      previousFriendsieState,
      dispatch,
      debounceUpdateSeeds,
      signer,
      globalAssets
    ]
  );

  // undo traits immediately and wait on API response for finality
  const undo = async () => {
    if (!previousFriendsieState || disableUndoTimer < 1) return;
    // disable finish build button
    dispatch(setDisableFinishBuild(true));

    // temp hold seed - in case of race condition
    let previous = previousFriendsieState;
    let current = friendsieState;

    setFriendsieState(previousFriendsieState);
    setSearchParams(createUrlFromState(previousFriendsieState));
    setPreviousFriendsieState(null);

    // if user is building for mint
    if (authToken && builderState === 'building') {
      let updateStatus = await undoSeed(authToken, builderState, previousFriendsieState);
      if (!updateStatus) {
        setFriendsieState(current);
        setPreviousFriendsieState(previous);
        toast.error('Undo failed. Options reverted to previous.');
      }

      // update is done, re-enable build finish button
      dispatch(setDisableFinishBuild(false));
    } else {
      dispatch(setDisableFinishBuild(false));
    }
  };

  // grab "pre-generated" random seed from state, and generate a new for the next random seed
  const randomFriendsie = async () => {
    // disable finish build button
    dispatch(setDisableFinishBuild(true));

    // temp hold seed - in case of race condition
    let previous = previousFriendsieState;
    let current = friendsieState;

    setPreviousFriendsieState(friendsieState);

    // preload and prepare the next random state
    let nextRandomState = randomizeFriendsie(globalAssets);

    // preload the next random state
    statePreloader(nextRandomState);
    setPreloadedRandomState(nextRandomState);

    // set new options
    setFriendsieState(preloadedRandomState);
    setSearchParams(createUrlFromState(preloadedRandomState));

    // if user is building
    if (authToken && builderState === 'building') {
      clearTimeout(updateSeedsTimeoutRef.current);
      updateSeedsTimeoutRef.current = setTimeout(() => debounceUpdateSeeds(current, previous, authToken, signer, builderState, preloadedRandomState), 200);
    } else {
      dispatch(setDisableFinishBuild(false));
    }
  };

  const changeTraitCategory = (traitData) => {
    if (traitData.name === 'face' && friendsieState.headFixed === 'fixed') return;
    if (traitData.name === 'sprout' && friendsieState.headNoSprout) return;

    setTraitCategory(traitData.name);
  };

  const cancelBuildTimeoutRef = useRef(null);

  // let user cancel the build within 10 seconds of calling it
  const cancelBuild = async () => {
    // clear build timeout
    if (cancelBuildTimeoutRef.current) {
      clearTimeout(cancelBuildTimeoutRef.current);
      dispatch(setBuildingFriendsieLoadingState(false));
    }
  };

  // Skip 10 second countdown and let user mint immediately
  const buildNowButton = async () => {
    if (cancelBuildTimeoutRef.current) {
      dispatch(setBuildButtonLoading(true));
      clearTimeout(cancelBuildTimeoutRef.current);
      let sceneImage = saveSceneAsImage();
      dispatch(buildFriendsie(authToken, builderState, signer, sceneImage, friendsieState));
    }
  };

  const build = async () => {
    // save scene as image to display in the "building friendsie" modal
    let sceneImage = saveSceneAsImage();
    dispatch(setBuildingFriendsieLoadingState(true));
    // set build timeout - user can cancel this if they click "cancel build" in "building friendsie" modal
    cancelBuildTimeoutRef.current = setTimeout(() => {
      dispatch(buildFriendsie(authToken, builderState, signer, sceneImage, friendsieState));
    }, 10000);
  };

  const closeBuilderModal = () => {
    // set loading to false to close modal, and clear the friendsie token ID the user just built
    dispatch(setBuildingFriendsieLoadingState(false));
    dispatch(setFriendsieTokenId(null));

    // they may have sat on builder screen for a while, so -
    // preload and prepare a new random state so it's not stale - this will be the friendsie they see when they close the modal
    let nextRandomState = randomizeFriendsie(globalAssets);

    // preload the next random state
    statePreloader(nextRandomState);
    setPreloadedRandomState(nextRandomState);

    randomFriendsie();
    toast.success(`You're now building your next Friendsie!`);
  };

  // redirect user to correct category if they're on a category thats not available for their seed
  useEffect(() => {
    if (!friendsieState || !traitCategory) return;
    if (
      (friendsieState.fixedModel && traitCategory !== 'head') ||
      (traitCategory === 'sprout' && friendsieState.headNoSprout) ||
      (traitCategory === 'face' && friendsieState.headFixed === 'fixed')
    ) {
      setTraitCategory('head');
    }
  }, [friendsieState, globalAssets, traitCategory]);

  // timer to keep track of undo - "undo" is in a few different components :( , so keep track globally
  useEffect(() => {
    if (builderState === 'building') {
      let disableUndoInterval = setInterval(() => {
        if (disableUndoTimer > 0) {
          dispatch(setDisableUndoTimer(disableUndoTimer - 1));
        } else {
          clearInterval(disableUndoInterval);
        }
      }, 1000);
      return () => {
        clearInterval(disableUndoInterval);
      };
    }
  }, [disableUndoTimer, builderState, dispatch]);

  // model ref for download
  const modelDownloadMesh = useRef();

  const downloadModel = useCallback((animations) => {
    const gltfExporter = new GLTFExporter();

    let downloadModel = modelDownloadMesh.current;

    const options = {
      binary: true,
      // animations: animations,
    };
    try {
      gltfExporter.parse(
        downloadModel,
        function (result) {
          if (result instanceof ArrayBuffer) {
            saveArrayBuffer(result, 'FriendsieModel.glb');
          } else {
            const output = JSON.stringify(result, null, 2);
            saveString(output, 'FriendsieModel.gltf');
          }
        },
        function (error) {
          console.log('An error happened: ', error);
        },
        options
      );
    } catch (error) {
      console.log('An error happened: ', error);
      // toast.error('Model download failed.');
    }
  }, []);

  const downloadRef = useRef(false);

  useEffect(() => {
    if (searchParams.get('downloadModel') && !active && loaded && downloadRef.current === false && entireScene) {
      downloadRef.current = true;
      setDownloadingModel(true);
      let finalArray = [];

      setTimeout(() => {
        saveScreenshot(entireScene);
        downloadModel(finalArray);
      }, 10000);
    }
  }, [active, downloadModel, loaded, searchParams, entireScene]);

  const saveSceneAsImage = () => {
    let imgData;
    try {
      let strMime = 'image/jpeg';
      imgData = entireScene.domElement.toDataURL(strMime, 1.0);
      // let domImage = imgData.replace(strMime, 'image/octet-stream');
      dispatch(setBuiltFriendsieImage(imgData));
      return imgData;
    } catch (e) {
      console.log(e);
      return;
    }
  };

  const [envMapState, setEnvMapState] = useState('empty_warehouse_01_1k.hdr');

  //  const [rendererInfo, setRendererInfo] = useState(null);

  useEffect(() => {
    if (mockObject && mockObject.quaternion && modelDownloadMesh.current) {
      mockObject.quaternion.copy(modelDownloadMesh.current.quaternion);
    }
  }, [modelDownloadMesh]);

  const rotateMesh = () => {
    if (modelDownloadMesh.current && mockObject) {
      // mockObject.rotation.y += Math.PI;
      modelDownloadMesh.current.rotation.y += Math.PI;
    }
  };

  var elem = document.documentElement;

  const [fullScreenMode, setFullScreenMode] = useState(false);

  document.addEventListener('fullscreenchange', (event) => {
    if (document.fullscreenElement || document.webkitFullscreenElement) {
      setFullScreenMode(true);
    } else {
      setFullScreenMode(false);
    }
  });

  const goFullScreen = () => {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  };

  const closeScreen = async () => {
    if (!document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  };

  return (
    <div className={`${loaded ? 'opacity-100' : 'opacity-0'} overflow-hidden touch-none ${classes.builderCanvas}`}>
      <CanvasComponent
        friendsieState={friendsieState}
        mockObject={mockObject}
        downloadingModel={downloadingModel}
        setEntireScene={setEntireScene}
        ref={modelDownloadMesh}
      />

      {builderState === 'building' && authToken && (!hasSyncedAssets || !isWsConnected) && (
        <div className={`${classes.connectionContainer} max-w-xl z-10 py-10 px-5 rounded-xl m-96 text-fbuilderGreen mx-auto font-FredokaOne`}>
          <p className="inline mr-4">Connecting</p>
          <svg className="animate-spin inline h-5 w-5 mr-3 text-white" viewBox="0 0 24 24">
            <path
              className="opacity-75 text-white"
              fill="#0dbd00"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <p className="mt-10"> If you're unable to connect, please ensure adblocker is disabled. If the problem persists please reach out on discord.</p>
        </div>
      )}

      {loaded && globalAssets && friendsieState && (
        <>
          <div className="w-full fixed top-0 text-center md:hidden ">
            <p className={`${classes.visitOnDesktopToMint} text-fbuilderGreen font-FredokaOne`}>PLEASE VISIT ON DESKTOP TO MINT</p>
          </div>
          {/* Frengen Logo */}
          <div className={`${classes.frengenLogoContainer}`}>
            <img draggable={false} className={`${classes.frengenLogoPainInMyAss}`} src={FrengenImg} alt="frengen" />
          </div>

          {/* Mint / Auction Box  */}
          {builderState !== 'share' && (
            <>
              <div className={`hidden md:block md:right-2 md:top-2 lg:right-24 lg:top-24 z-10 fixed`}>
                {/* to hide/ unhide mint */}
                {/* <div className="hidden"> */}
                {/* <Mint
                  signer={signer}
                  connectHandler={connectHandler}
                  disconnectHandler={disconnectHandler}
                  build={build}
                  // resetWebSocketAssets={resetWebSocketAssets}
                /> */}
                {/* </div> */}
                <div className="md:hidden lg:block">
                  <RandomUndo previousFriendsieState={previousFriendsieState} undo={undo} randomFriendsie={randomFriendsie} />
                </div>
                {/* This is friendsie stats component, not r3f drei stats */}
                {builderState === 'building' && <Stats />}
              </div>

              <TraitCategory
                traitCategory={traitCategory}
                friendsieState={friendsieState}
                previousFriendsieState={previousFriendsieState}
                undo={undo}
                randomFriendsie={randomFriendsie}
                changeTraitCategory={changeTraitCategory}
                fullScreenMode={fullScreenMode}
                goFullScreen={goFullScreen}
                closeScreen={closeScreen}
              />
              <TraitSelector
                traitCategory={traitCategory}
                friendsieState={friendsieState}
                handleStateChange={handleStateChange}
              />

              <div className="hidden lg:block fixed bottom-0 right-0 m-2">
                <button onClick={fullScreenMode ? closeScreen : goFullScreen} className="friendsie-pill m-0 p-0">
                  {fullScreenMode ? 'EXIT FULLSCREEN' : 'FULLSCREEN'}
                </button>
              </div>
            </>
          )}
          {builderState === 'share' && (
            <div className={`hidden  md:block right-2 top-2 z-10 fixed`}>
              <ShareWidget
                signer={signer}
                connectHandler={connectHandler}
                disconnectHandler={disconnectHandler}
                seedId={friendsieTokenId}
                // resetWebSocketAssets={resetWebSocketAssets}
              />
            </div>
          )}

          {/* Loading Models Overlay */}
          {active && loaded && globalAssets && friendsieState && previousFriendsieState && (
            <div className="select-none fixed top-1/2 place-items-center inset-x-0 mx-auto ">
              <div className="select-none flex place-content-center flex-col ">
                <p className="text-center text-fLandingPageBlue text-xl p-font">LOADING</p>
                <div className="select-none  place-content-center  inset-x-0 mx-auto">
                  {/* <div className={`${classes.dotPulse} place-items-center`}></div> */}
                </div>
              </div>
            </div>
          )}

          {/* Building Friendsie Overlay */}
          {buildingFriendsieLoadingState && <BuilderModal cancelBuild={cancelBuild} closeBuilderModal={closeBuilderModal} buildNowButton={buildNowButton} />}

          {/* Hidden Stuff */}
          <div className="z-50 fixed top-0 left-1/4">
            <div className="flex place-content-center">
              <div>
                {/* <button onClick={downloadModel} id="secretdownload" className="hidden" /> */}
                <div id="secretdownloadresult" className="hidden">
                  null
                </div>
                <div id="secretscreenshotresult" className="hidden">
                  null
                </div>
                {/* <div className="fixed top-10 left-10">
                  <button onClick={() => rotateMesh()} className="bg-black text-white rounded-xl p-2">
                    Rotate Model
                  </button>
                </div> */}

                {/* <div className="fixed top-10 left-10">
                  <button onClick={() => saveAsImage()} className="bg-black text-white rounded-xl p-2">
                    screenshot
                  </button>
                  <img src={friendsieImage} alt="download" className="w-64" />
                </div> */}

                <DebugTools friendsieState={friendsieState} setEnvMapState={setEnvMapState} envMapState={envMapState} animationDuration={animationDuration} />
              </div>
            </div>
          </div>
          <></>
        </>
      )}
    </div>
  );
};

export default FriendsieBuilder;

// useTexture.preload('/studio008.hdr');

const blobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

function saveArrayBuffer(buffer, filename) {
  save(new Blob([buffer], { type: 'application/octet-stream' }), filename);
}

export function save(blob, filename) {
  blobToBase64(blob).then((r) => {
    let div = document.getElementById('secretdownloadresult');
    console.log('GLB EXPORT');
    // console.log(r);
    div.innerText = r;
    // console.log('secretdownloadresult inner text: ', r);
    // let link = document.createElement('a');
    // link.style.display = 'none';
    // document.body.appendChild(link); // Firefox workaround, see #6594
    // link.href = URL.createObjectURL(blob);
    // link.download = filename;
    // link.click();
  });
}
function saveString(text, filename) {
  save(new Blob([text], { type: 'text/plain' }), filename);
}

function saveScreenshot(entireScene) {
  let imgData;
  try {
    let strMime = 'image/jpeg';
    imgData = entireScene.domElement.toDataURL(strMime, 1.0);

    let div = document.getElementById('secretscreenshotresult');
    console.log('SCREENSHOT EXPORT');
    // console.log(imgData);
    div.innerText = imgData;

    return imgData;
  } catch (e) {
    console.log(e);
    return;
  }
}
