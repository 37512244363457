import { useAnimations, useCubeTexture, useGLTF, useTexture } from '@react-three/drei';
import { memo, useEffect, useRef, useState } from 'react';
import * as THREE from 'three';

import { useCallback } from 'react';
// import { useControls } from 'leva';
import { useFrame } from '@react-three/fiber';

const placeholderFace = '/textures/Faces_Smiles.png';

let primaryMaterial = new THREE.MeshStandardMaterial({
  //color: 0xff2222,
  visible: true,
});

let faceMaterial = new THREE.MeshStandardMaterial({
  alphaTest: 0.1,
  visible: true,
  opacity: 1,
  transparent: true,
});

let unavailableMaterial = new THREE.MeshPhongMaterial({
  format: THREE.RGBAFormat,
  visible: true,
  opacity: 1,
  transparent: true,
  color: 'grey',
});

const Head = ({
  friendsieState,
  face,
  entireState,
  downloadingModel,
  // setHeadAnimation
}) => {
  const faceTexture = useRef();
  const renderMesh = useRef();
  const group = useRef();
  const primitiveRef = useRef();

  const modelMaterialRef = useRef(null);

  const modelData = useGLTF(friendsieState.asset_url);
  // setHeadAnimation(modelData.animations);

  // const { ref, mixer, names, actions } = useAnimations(testHead.animations, group);

  primaryMaterial = modelData.materials.Material ? modelData.materials.Material : primaryMaterial;

  let loadedTexture = useTexture(face ? face.asset_url : placeholderFace);

  // const animationData = useAnimations(modelData.animations, group);

  const [modelGeometryState, setModelGeometryState] = useState(null);
  const [modelMaterialState, setModelMaterialState] = useState(null);
  const [modelSkeletonState, setModelSkeletonState] = useState(null);
  // const [nodeDataState, setNodeDataState] = useState(null);

  const setupModel = useCallback(() => {
    // model files have one group (children[0]), consisting of bones [0] and skinnedMesh [1]
    const originalMesh = modelData.scene.children[0].children[0];
    // const originalMesh = modelData.scene.children[0].children[1];

    const baseTexture = originalMesh.material.map;

    // TODO: Setup materail generation so models dont share materials. (allows for quicker switching)
    primaryMaterial.map = baseTexture;
    primaryMaterial.normalMap = originalMesh.material.normalMap;
    // primaryMaterial.transparent = true;
    // primaryMaterial.opacity = 0.5;
    // primaryMaterial.alphaTest = 0.1;

    const modelGeometry = originalMesh.geometry;
    let modelMaterial;

    // get model vertices
    // const count = modelGeometry && modelGeometry.index.count;

    // This is the magic
    if (entireState.headFixed === 'variable') {
      // if head is not fixed, add face to head material
      modelGeometry.clearGroups();
      modelGeometry.addGroup(0, 420690, 0);
      // modelGeometry.addGroup(0, Infinity, 1);
      modelGeometry.addGroup(0, 420690, 1);
      // primaryMaterial.format = THREE.RGBAFormat;
      // primaryMaterial.transparent = true;
      // primaryMaterial.opacity = 0.15;
      // primaryMaterial.wireframe = true;

      // faceMaterial.format = THREE.RGBAFormat;
      // faceMaterial.transparent = true;
      // faceMaterial.opacity = 1;

      modelMaterial = [primaryMaterial, faceMaterial];

      // modelMaterialRef.current.format = THREE.RGBAFormat;
      // modelMaterialRef.current.transparent = true;
      // modelMaterialRef.current.opacity = 0.15;
    } else {
      modelMaterial = primaryMaterial;
      // modelMaterial.format = THREE.RGBAFormat;
      // modelMaterial.wireframe = true;

      // modelMaterial.transparent = true;
      // modelMaterial.opacity = 0.15;
    }

    // renderMesh.current = new THREE.Mesh(modelGeometry, modelMaterialArray);

    setModelGeometryState(modelGeometry);

    setModelMaterialState(modelMaterial);

    // let skeleton = modelData.nodes[getHeadModelNodeNames(friendsieState.name).skeleton].skeleton;
    let skeleton = modelData.nodes['X'].skeleton;

    setModelSkeletonState(skeleton);

    // let nodeData = modelData.nodes.Root;
    // setNodeDataState(nodeData);
  }, [modelData, entireState.headFixed]);

  // Swap out/load the model when toggled
  useEffect(() => {
    setupModel();
  }, [friendsieState, setupModel]);

  // // TODO Try merging into single use effect
  // // Set the face based on face prop input
  useEffect(() => {
    faceTexture.current = loadedTexture;
    faceTexture.current.flipY = false;
    faceTexture.current.wrapS = faceTexture.current.wrapT = THREE.RepeatWrapping;
    faceTexture.current.needsUpdate = true;
    faceMaterial.map = faceTexture.current;
  }, [face, faceTexture, loadedTexture]);

  // useEffect(() => {
  //   animationData.actions[animationData.names[0]].reset().play();
  //   return () => {
  //     animationData.actions[animationData.names[0]].stop();
  //   };
  // }, [animationData, friendsieState, face, entireState, traitCategory]);

  // useEffect(() => {
  //   actions[names[0]].reset().play();
  //   return () => actions[names[0]].stop();
  // }, [actions, names]);

  let testMixer;

  if (modelData.animations.length && modelData.nodes.Root && !downloadingModel) {
    testMixer = new THREE.AnimationMixer(modelData.nodes.Root);
    modelData.animations.forEach((clip) => {
      const action = testMixer.clipAction(clip);
      action.play();
    });
  }

  useFrame((state, delta) => {
    testMixer?.update(delta);
  });

  let change;
  const opacityRef = useRef(0);
  useFrame((state, delta) => {
    unavailableMaterial.opacity = Math.abs(Math.sin(state.clock.elapsedTime * 2));
    // opacityRef.current = Math.abs(Math.sin(state.clock.elapsedTime * 2));
    // if (modelMaterialRef.current && modelMaterialRef.current.opacity) {
    //   change = Math.abs(Math.sin(state.clock.elapsedTime * 2));
    //   modelMaterialRef.current.opacity = change;
    // } else if (modelMaterialRef.current.length) {
    //   change = Math.abs(Math.sin(state.clock.elapsedTime * 2));
    //   modelMaterialRef.current[0].opacity = modelMaterialRef.current[1].opacity = change;
    //   // modelMaterialRef.current[1].opacity = change;
    // }
  });

  // const envMap = useTexture('/logo192.png');

  return (
    <group
      ref={group}
      castShadow
      receiveShadow

      // position={[0, 0.15, 0]}
      // scale={[1.06, 1.06, 1.06]}
      // scale={[0.1, 0.1, 0.1]}
      // rotation={[0, 0, -Math.PI / 4]}
    >
      {/* <primitive ref={primitiveRef} object={modelData.nodes.Root} /> */}
      <primitive ref={primitiveRef} object={modelData.nodes.Root} />
      {modelGeometryState && modelMaterialState && modelSkeletonState && (
        // <skinnedMesh ref={renderMesh} geometry={modelGeometryState} material={modelMaterialState} skeleton={modelSkeletonState} />
        <skinnedMesh
          castShadow
          receiveShadow
          ref={renderMesh}
          geometry={modelGeometryState}
          skeleton={modelSkeletonState}
          // material={true ? modelMaterialState : unavailableMaterial}>
          material={modelMaterialState}>
          {/* <meshStandardMaterial color={'black'} attach="material" /> */}
        </skinnedMesh>
      )}
      {/* {renderMesh.current && <primitive object={renderMesh.current} />} */}
      {/* <primitive object={scene} /> */}
    </group>
  );
};

export default Head;
