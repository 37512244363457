import React from 'react';
import MobileLanding from '../components/Landing/MobileLanding';
import TabletLanding from '../components/Landing/TabletLanding';
import DevLanding from '../components/Landing/DevLanding';
import { useMediaQuery } from 'react-responsive';
import { ToastContainer } from 'react-toastify';

const Landing = ({ signer, connectHandler, disconnectHandler }) => {
  // This is necessary due to FullPage.js reliance on CSS classes and their interference with tailwind...
  // seperate components required for now :(
  // const isDesktop = useMediaQuery({ minWidth: 1024 });
  // const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  // const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      <DevLanding signer={signer} connectHandler={connectHandler} disconnectHandler={disconnectHandler} />
      <ToastContainer />
    </>
  );
};

export default Landing;
