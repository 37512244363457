import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { ethers } from 'ethers';
import { toast } from 'react-toastify';

const initialState = {
  userSignature: null,
  userAuthToken: '',
  userMintTokens: { tokens: [], pending_tokens: [] },
  builderState: 'preview', // STATES:  'preview', 'building', or 'completed', 'share'
  authTokenId: 1,
  ghostCloudAllowlist: null,
  superGoldenCloudAllowlist: null,
  claimedGhostCloud: null,
  claimedSuperGoldenCloud: null,
  buildingFriendsieLoadingState: false,
  friendsieTokenId: null,
  christiesAuctionWinnerError: null,
  friendsieReservedTimer: 600, // start at 10 minutes
  disableFinishBuild: false,
  builtFriendsieImage: null,
  disableUndoTimer: 30,
  buildButtonLoading: false,
  sharePageLinks: null,
};

const web3Provider = new ethers.providers.AlchemyProvider(
  process.env.REACT_APP_NETWORK,
  process.env.REACT_APP_ALCHEMY_API_KEY
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserSignature: (state, action) => {
      state.userSignature = action.payload;
    },
    setUserAuthToken: (state, action) => {
      state.userAuthToken = action.payload;
    },
    setUserTokens: (state, action) => {
      state.userMintTokens = action.payload;
    },
    setBuilderState: (state, action) => {
      state.builderState = action.payload;
    },
    setGhostCloudAllowlist: (state, action) => {
      state.ghostCloudAllowlist = action.payload;
    },
    setSuperGoldenCloudAllowlist: (state, action) => {
      state.superGoldenCloudAllowlist = action.payload;
    },
    setBuildingFriendsieLoadingState: (state, action) => {
      state.buildingFriendsieLoadingState = action.payload;
    },
    setFriendsieTokenId: (state, action) => {
      state.friendsieTokenId = action.payload;
    },
    setClaimedGhostCloud: (state, action) => {
      state.claimedGhostCloud = action.payload;
    },
    setClaimedSuperGoldenCloud: (state, action) => {
      state.claimedSuperGoldenCloud = action.payload;
    },
    setChristiesAuctionWinnerError: (state, action) => {
      state.christiesAuctionWinnerError = action.payload;
    },
    setFriendsieReservedTimer: (state, action) => {
      state.friendsieReservedTimer = action.payload;
    },
    setDisableFinishBuild: (state, action) => {
      state.disableFinishBuild = action.payload;
    },
    setBuiltFriendsieImage: (state, action) => {
      state.builtFriendsieImage = action.payload;
    },
    setDisableUndoTimer: (state, action) => {
      state.disableUndoTimer = action.payload;
    },
    setBuildButtonLoading: (state, action) => {
      state.buildButtonLoading = action.payload;
    },
    setSharePageLinks: (state, action) => {
      state.sharePageLinks = action.payload;
    },
  },
});

export const {
  setUserTokens,
  setUserAuthToken,
  setUserSignature,
  setBuilderState,
  setGhostCloudAllowlist,
  setSuperGoldenCloudAllowlist,
  setBuildingFriendsieLoadingState,
  setFriendsieTokenId,
  setClaimedGhostCloud,
  setClaimedSuperGoldenCloud,
  setChristiesAuctionWinnerError,
  setFriendsieReservedTimer,
  setDisableFinishBuild,
  setBuiltFriendsieImage,
  setDisableUndoTimer,
  setBuildButtonLoading,
  setSharePageLinks,
} = userSlice.actions;

export const getUserSignature = (signer, disconnect) => async (dispatch) => {
  if (!signer) return;

  if (
    signer &&
    signer.provider &&
    signer.provider.provider &&
    signer.provider.provider.bridge === 'https://bridge.walletconnect.org'
  ) {
    toast.error('Please sign in on your device to continue.');
  }
  if (
    signer &&
    signer.provider &&
    signer.provider.connection &&
    signer.provider.connection.url === 'metamask'
  ) {
    toast.success('Please sign in metamask to continue.', {
      autoClose: 1000,
      hideProgressBar: true,
    });
  }

  try {
    const userSignature = await signer.signMessage(
      'Welcome to fRiENDSiES\n\nClick "Sign" to sign in to access your assets.'
    );
    const userAddress = await signer.getAddress();
    if (userAddress && userSignature) {
      // dispatch(getAuth(userSignature, userAddress, signer));
      dispatch(setUserSignature(userSignature));

      dispatch(userAccessSharePageAssets(userAddress, userSignature));
    } else {
      // user denied signature, so disconnect
      if (disconnect) disconnect();
    }
  } catch (error) {
    toast.error('Denied signing request. Please sign in to continue.');

    // console.log("Failed to get user signer");
    console.log(error);
    // user denied signature, so disconnect
    if (disconnect) disconnect();
  }
};

export const getUserTokens = (signer) => async (dispatch) => {
  if (!signer) return;
  const userAddress = await signer.getAddress();
  if (userAddress) {
    try {
      const result = await axios.get(
        process.env.REACT_APP_SERVICE_URL + `/wallets/${userAddress}`,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (result) {
        dispatch(
          setUserTokens({
            tokens: result.data.tokens,
            pending_tokens: result.data.pending_tokens,
          })
        );
      }
    } catch (error) {
      console.log('axios error: ', error);
    }
  }
};

export const getAuth = (sig, address, signer) => async (dispatch, getState) => {
  if (!sig || !address) return;

  let requestBody = { sig: sig, address: address };

  console.log('request body: ', requestBody);

  try {
    const result = await axios.post(
      process.env.REACT_APP_SERVICE_URL + `/auth`,
      requestBody,
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      }
    );

    if (result && result.data.token) {
      // TODO: correctly verify they're authenticated on the frontend. Do we look at cookie value? check if cookie exists?
      dispatch(setUserAuthToken(result.data.token));
      localStorage.setItem(address, result.data.token);
      setUserSignature(sig);

      // enter builder right away on success
      // dispatch(userEnterBuilder());
    }
  } catch (error) {
    console.log('status: ', error);

    // toast.success('Auth success');
  }
};

export const userAccessSharePageAssets =
  (userAddress, userSignature) => async (dispatch, getState) => {
    let requestBody = { sig: userSignature, address: userAddress };

    if (true) {
      try {
        const result = await axios.post(
          `${process.env.REACT_APP_SERVICE_URL}/tokenGate`,
          requestBody,
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          }
        );

        if (result && result.data) {
          dispatch(setSharePageLinks(result.data));
        }
      } catch (error) {
        console.log('status: ', error);

        toast.error(error.response.data.message);
      }
    }
  };

export const userEnterBuilder = () => async (dispatch, getState) => {
  let state = getState();
  if (
    state.user.userAuthToken &&
    state.user.userMintTokens.pending_tokens.length > 0
  ) {
    dispatch(setBuilderState('building'));
    // toast.success('Entered builder mode');
  }
};

export const userLeaveBuilder = () => async (dispatch, getState) => {
  let state = getState();
  dispatch(setUserAuthToken(''));
  dispatch(setBuilderState('preview'));
  // toast.success('Entered builder mode');
};

export const getGhostCloudAllowlist =
  (signer) => async (dispatch, getState) => {
    if (!signer) return;

    const userAddress = await signer.getAddress();

    try {
      const result = await axios.get(
        process.env.REACT_APP_SERVICE_URL +
          `/allowlist?wallet=${userAddress}&list=ghostcloud`,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (result && result.status === 200) {
        dispatch(setGhostCloudAllowlist(result.data));
      }
    } catch ({ error }) {
      // console.log('error: ', error);

      dispatch(setGhostCloudAllowlist(null));
      // 400 status
      // no Ghost Cloud keys available
    }
  };

export const getSuperGoldenCloudAllowlist =
  (signer) => async (dispatch, getState) => {
    if (!signer) return;

    const userAddress = await signer.getAddress();

    try {
      const result = await axios.get(
        process.env.REACT_APP_SERVICE_URL +
          `/allowlist?wallet=${userAddress}&list=supergoldencloud`,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (result && result.status === 200) {
        dispatch(setSuperGoldenCloudAllowlist(result.data));
      }
    } catch (error) {
      dispatch(setSuperGoldenCloudAllowlist(null));
      // 400 status
      // no golden cloud keys available
    }
  };

export const buildFriendsie =
  (authToken, builderState, signer, sceneImage, friendsieState) =>
  async (dispatch, getState) => {
    if (builderState !== 'building' || !authToken) {
      toast.error('Mint friendsie denied');
    }
    dispatch(setBuildingFriendsieLoadingState(true));

    let headFixed = friendsieState.headFixed === 'fixed';
    let fixedModel = friendsieState.fixedModel;
    let noSprout = friendsieState.headNoSprout;

    const requestBody = {
      log_public_blob: sceneImage,
      log_public_backpiece: !fixedModel ? friendsieState.backpiece.id : '',
      log_public_body: !fixedModel ? friendsieState.body.id : '',
      log_public_face: !fixedModel && !headFixed ? friendsieState.face.id : '',
      log_public_hand: !fixedModel ? friendsieState.accessory.id : '',
      log_public_head: friendsieState.head.id,
      log_public_shoe: !fixedModel ? friendsieState.shoe.id : '',
      log_public_sprout:
        !fixedModel && !noSprout ? friendsieState.sprout.id : '',
      use_public_log: false,
    };
    const userAddress = await signer.getAddress();

    try {
      const buildResult = await axios.post(
        `${process.env.REACT_APP_SERVICE_URL}/seeds/build`,
        requestBody,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (buildResult && buildResult.status === 200) {
        // Build was successful!

        // try to reauth - see if user has any more tokens
        const reAuthResult = await dispatch(reAuthUser(authToken));

        // reauth was successful and user has more tokens, so dont trigger the `share` state -
        //  instead send user right back in. re-fetch their pending tokens
        if (reAuthResult) {
          dispatch(setUserAuthToken(reAuthResult));
          const userToken = await dispatch(getUserTokens(signer));
          localStorage.setItem(userAddress, reAuthResult);
        } else {
          // reauth failed - user is done building, no more tokens. show the share page
          localStorage.removeItem(userAddress);
          dispatch(setUserAuthToken(''));

          dispatch(setBuilderState('share'));
        }

        // set friendsie seed tokenId
        console.log('BUILD COMPLETE TOKEN ID: ', buildResult.data.tokenId);
        dispatch(setFriendsieTokenId(buildResult.data.tokenId));
        dispatch(setBuildButtonLoading(false));
        return true;
      } else {
        toast.error('Failed to build Friendsie');
        dispatch(setBuildingFriendsieLoadingState(false));
        dispatch(setBuildButtonLoading(false));
      }
    } catch (error) {
      console.log('axios error: ', error);
      toast.error('Failed to build Friendsie');
      dispatch(setBuildingFriendsieLoadingState(false));
      dispatch(setBuildButtonLoading(false));
    }
  };

export const reAuthUser = (authToken) => async (dispatch, getState) => {
  const requestBody = {};

  try {
    // User built successfully. Try Re-auth to see if they have any more pending tokens
    const reauthResult = await axios.post(
      `${process.env.REACT_APP_SERVICE_URL}/reauth`,
      requestBody,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    if (reauthResult && reauthResult.status === 200) {
      // return new auth token
      return reauthResult.data.token;
    } else {
      return null;
    }
  } catch (error) {
    // if it fails, return null
    console.log('axios error: ', error);
    return null;
  }
};

// instead of reworking logic of other functions, combine auth and signature functions here to fit rebuild functionality
export const rebuildFriendsie =
  (signer, tokenId) => async (dispatch, getState) => {
    if (!signer || !tokenId) return;

    // copy getUserSignature() logic from above, as the signature is needed to reset the tokenId for friendsie rebuild
    if (
      signer &&
      signer.provider &&
      signer.provider.provider &&
      signer.provider.provider.bridge === 'https://bridge.walletconnect.org'
    ) {
      toast.error('Please sign in on your device to continue.');
    }

    // FLOYD - commented out 4/5/22 on `rebuild-flow` branch --> this triggers even if I'm signed in to Metamask and I see this toast as I enter the builder to "rebuild"
    //
    // if (signer && signer.provider && signer.provider.connection && signer.provider.connection.url === 'metamask') {
    //   toast.success('Please sign in metamask to continue.', {
    //     autoClose: 1000,
    //     hideProgressBar: true,
    //   });
    // }

    dispatch(setBuildButtonLoading(true));

    // first, get user signature from metamask
    try {
      const userSignature = await signer.signMessage(
        'Welcome to fRiENDSiES\n\nClick "Sign" to sign in to access your assets.'
      );
      const userAddress = await signer.getAddress();
      if (userAddress && userSignature) {
        // string was not being parsed to int when inline in the requestBody below? idk
        let integerTokenId = parseInt(tokenId);

        let requestBody = {
          sig: userSignature,
          address: userAddress,
          reset_token_id: integerTokenId,
        };

        // if user signs message, see if they can rebuild the tokenId passed in
        try {
          const result = await axios.post(
            process.env.REACT_APP_SERVICE_URL + `/auth`,
            requestBody,
            {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true,
            }
          );

          if (result && result.data.token) {
            // user can rebuilt the toke, so proceed with normal auth flow and direct them to the builder
            localStorage.setItem(userAddress, result.data.token);
            dispatch(setUserAuthToken(result.data.token));

            dispatch(setBuilderState('building'));
            dispatch(setBuildButtonLoading(false));
          }
        } catch (error) {
          console.log('status: ', error);

          toast.error(error.response.data.message);
          dispatch(setChristiesAuctionWinnerError(error.response.data.message));
          // toast.success('Auth success');
          dispatch(setBuildButtonLoading(false));
        }
        // dispatch(getUserTokens(userAddress, userSignature));
      }
    } catch (error) {
      console.log('Failed to get user signer');
      console.log(error);
      dispatch(setBuildButtonLoading(false));
    }
  };

export default userSlice.reducer;
