import { useState, Fragment } from 'react'
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, CheckIcon, ExclamationIcon } from '@heroicons/react/solid'
import axios from "axios";

function ClaimModal(props) {
  const {
    isOpen,
    setIsOpen,
    checkInbox,
    setCheckInbox,
  } = props

  let [isPending, setPending] = useState(false)
  let [email, setEmail] = useState("")
  let [tryAgain, setTryAgain] = useState(false)

  const closeModal = () => {
    setIsOpen(false)
    window.setTimeout(() => {
      setPending(false)
      setTryAgain(false)
      setCheckInbox(false)
      setEmail("")
    }, 200); // poor mans solution to prevent UI flicker
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const continueWithNiftyGateway = (event) => {
    event.preventDefault();
    window.location.href = process.env.REACT_APP_SERVICE_URL + "/verifyNiftyGateway";
  };

  const sendLinkToEmail = async (event) => {
    event.preventDefault();
    setPending(true)

    try {
      console.log("sending email", email)
      const response = await axios.post(process.env.REACT_APP_SERVICE_URL + "/verifyEmail", {
        email: email,
      });

      setPending(false)

      if (response.data && response.data.allowed === false) {
        setTryAgain(true)
        setCheckInbox(false)
      } else {
        setTryAgain(false)
        setCheckInbox(true)
      }

    } catch ({ response }) {
      setPending(false)
      setCheckInbox(false)
      setTryAgain(true)
      console.error(response);
      return;
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal} open={isOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className="
                inline-block
                align-bottom
                bg-white
                rounded-lg
                px-4
                pt-5
                pb-4
                text-left
                overflow-hidden
                shadow-xl
                transform
                transition-all
                sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6
                text-xl
              "
            >
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="
                    bg-white
                    rounded-md
                    text-gray-400
                    hover:text-gray-500
                  "
                  onClick={() => setIsOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              {!checkInbox && !tryAgain && (
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-2xl font-medium text-gray-900">
                      Email Verification
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-gray-500">
                        We need to verify your email address before you can mint your Super Golden Cloud Key. If you've
                        purchased a FriendsWithYou NFT in the past, please login with NiftyGateway to verify.
                      </p>
                    </div>
                  </div>

                  <div className="mt-6">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="
                      shadow-sm
                      focus:ring-black focus:border-black
                      block
                      w-full
                      border
                      border-gray-300
                      rounded-md
                      px-2
                      py-2
                      mb-2
                      outline-none
                    "
                      value={email}
                      onChange={handleEmailChange}
                      disabled={isPending}
                      placeholder="you@example.com"
                    />

                    <button
                      type="button"
                      className="
                      inline-flex
                      justify-center
                      w-full
                      rounded-md
                      border border-transparent
                      shadow-sm
                      px-4
                      py-2
                      bg-gray-800
                      text-base
                      font-medium
                      text-white
                      hover:bg-gray-700
                      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black
                    "
                      disabled={isPending}
                      onClick={sendLinkToEmail}
                    >
                      {isPending && (<span>Sending, please wait ...</span>)}
                      {!isPending && (<span>Send link to my email address</span>)}
                    </button>
                  </div>

                  <div className="border-b border-gray-400 mt-4 mb-4">
                    <div className="relative left-1/2 -ml-12 -bottom-3 inline bg-white text-gray-500 px-2">Otherwise</div>
                  </div>

                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="
                      inline-flex
                      justify-center
                      w-full
                      rounded-md
                      border border-transparent
                      shadow-sm
                      px-4
                      py-2
                      bg-gray-800
                      text-base
                      font-medium
                      text-white
                      hover:bg-gray-700
                      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black
                    "
                      onClick={continueWithNiftyGateway}
                    >
                      Continue with NiftyGateway
                    </button>
                  </div>


                </div>
              )}
              {(checkInbox || tryAgain) && (
                <div>
                  {checkInbox &&
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                      <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>}
                  {tryAgain &&
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                      <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>}
                  <div className="mt-3 text-center text-2xl sm:mt-5">
                    <Dialog.Title as="h3" className="text-3xl font-medium text-gray-900">
                      {checkInbox && <span>Email sent</span>}
                      {tryAgain && <span>Try again</span>}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-gray-500">
                        {checkInbox && <span>Check your inbox, you should receive an email in just a few seconds.</span>}
                        {tryAgain && <span>Your email is not whitelisted.<br />You can try another email.</span>}
                      </p>
                    </div>
                    <div className="mt-5 sm:mt-6">
                      <button
                        type="button"
                        className="
                          inline-flex
                          justify-center
                          w-full
                          rounded-md
                          border border-transparent
                          shadow-sm
                          text-xl
                          px-4
                          py-2
                          bg-gray-800
                          text-base
                          font-medium
                          text-white
                          hover:bg-gray-700
                          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                        onClick={closeModal}>Done</button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default ClaimModal;
