import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { ethers } from 'ethers';
import { toast } from 'react-toastify';

const initialState = {
  assets: null,
  syncedAssets: false
};

export const assetsSlice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    setSyncedAssets: (state, action) => {
      state.syncedAssets = action.payload
    },
    setAssets: (state, action) => {
      state.assets = action.payload;
    },
    setAvailableAsset: (state, action) => {
      let message = action.payload
      if(!message || !message.part || !message.id) {
        console.log("Dropping invalid message.")
        return
      }

      if(!state.assets[message.part][message.id]) {
        console.log("Skipping state update for asset that no longer exists")
        return
      }

      state.assets[message.part][message.id].available = true
    },
    setUnavailableAsset: (state, action) => {
      let message = action.payload
      if(!message || !message.part || !message.id) {
        console.log("Dropping invalid message.")
        return
      }

      if(!state.assets[message.part][message.id]) {
        console.log("Skipping state update for asset that no longer exists")
        return
      }

      state.assets[message.part][message.id].available = false
    },
  },
});

export const {
  setAssets,
  setAvailableAsset,
  setUnavailableAsset,
  setSyncedAssets
} = assetsSlice.actions;

export default assetsSlice.reducer;