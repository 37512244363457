import { ReactComponent as InstagramIcon } from "../../assets/instagram-brands.svg";
import { ReactComponent as TwitterIcon } from "../../assets/twitter-brands.svg";
import { Outlet } from "react-router-dom";
import React from "react";
import { Link } from "react-router-dom";
import classes from "./Header.module.css";
// import WrongNetworkWarning from "./components/WrongNetworkWarning";
import discordIcon from "../../assets/discord.png";
import openseaIcon from "../../assets/opensea.png";
import LittleRainbow from "../../assets/images/LittleRainbow.png";
import { useSelector } from "react-redux";

// const provider = new ethers.providers.AlchemyProvider("rinkeby", "eW01hYxRpRNewnqkS92AP40W9CFPJS8D")
// const websocketProvider = ethers.providers.AlchemyProvider.getWebSocketProvider("rinkeby", "eW01hYxRpRNewnqkS92AP40W9CFPJS8D")

const Header = (props) => {
  const { showLogo, signer, connectHandler, disconnectHandler } = props;

  return (
    <div className="absolute z-10 top-0 left-2 w-screen">
      <header>
        {/*<WrongNetworkWarning requiredNetwork={process.env.REACT_APP_NETWORK} signer={signer}/>*/}
        <div className="mx-auto">
          {/* TODO: Pizza menu on mobile */}

          {/* <div>
              {showLogo && (
                <Link to="/">
                  <img src="/images/friendsies_header.png" className="md:h-16 h-12 -top-2 hidden md:block relative" alt="header" />
                </Link>
              )}
            </div> */}
          <div className="flex justify-start mt-2">
            <a
              href="https://discord.gg/friendswithyou"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={discordIcon}
                className="w-6 h-6 md:w-8 md:h-8 py-2 max-w-8 mr-2 text-white bg-gray-400 p-1 rounded-lg "
                alt="discord"
              />
            </a>
            <a
              href="https://opensea.io/collection/friendsies"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={openseaIcon}
                className="w-6 h-6 md:w-8 md:h-8 mr-2 text-white bg-gray-400 p-1 rounded-lg"
                alt="opensea"
              />
            </a>
            <a
              href="https://twitter.com/fRiENDSiES_Ai"
              target="_blank"
              rel="noreferrer"
            >
              <TwitterIcon className="w-6 h-6 md:w-8 md:h-8 mr-2 text-white bg-gray-400 p-1 rounded-lg" />
            </a>
            <a
              href="https://www.instagram.com/friendswithyou/"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIcon className="w-6 h-6 md:w-8 md:h-8 mr-2 text-white bg-gray-400 p-1 rounded-lg" />
            </a>

            {/* <img
              src={LittleRainbow}
              alt="little-rainbow"
              className={`h-4 md:h-8 mt-3 ${classes.enterQueueRainbow}`}
            /> */}

            {/* {!signer && (
              <button
                className={`text-xs md:text-base absolute top-0 rounded-br-xl rounded-bl-xl  px-2 py-1 font-bold  ${classes.connectWalletButton}`}
                onClick={connectHandler}>
                CONNECT WALLET
              </button>
            )}
            {signer && (
              <button
                className={`text-xs md:text-base absolute top-0  rounded-br-xl rounded-bl-xl   px-2 py-1 font-bold   ${classes.connectWalletButton}`}
                onClick={disconnectHandler}>
                DISCONNECT
              </button>
            )} */}
          </div>

          {showLogo && (
            <Link to="/">
              <img
                src="/images/friendsies_header.png"
                className="mx-auto md:hidden"
                alt="header"
              />
            </Link>
          )}
        </div>
      </header>
      <Outlet />
      {/* TODO: Footer */}
    </div>
  );
};

export default Header;
