import { ReactComponent as InstagramIcon } from "./assets/instagram-brands.svg";
import { ReactComponent as TwitterIcon } from "./assets/twitter-brands.svg";
import { Outlet } from "react-router-dom";
import React from 'react';
import { Link } from "react-router-dom";
// import WrongNetworkWarning from "./components/WrongNetworkWarning";
import discordIcon from './assets/discord.png';
import openseaIcon from './assets/opensea.png';


// const provider = new ethers.providers.AlchemyProvider("rinkeby", "eW01hYxRpRNewnqkS92AP40W9CFPJS8D")
// const websocketProvider = ethers.providers.AlchemyProvider.getWebSocketProvider("rinkeby", "eW01hYxRpRNewnqkS92AP40W9CFPJS8D")

function App(props) {
  const {
    showLogo,
    signer,
    connectHandler,
    disconnectHandler
  } = props

  return (
    <div className="App">
      <header className="App-header">
        {/*<WrongNetworkWarning requiredNetwork={process.env.REACT_APP_NETWORK} signer={signer}/>*/}
        <div className="container mx-auto px-6 lg:px-40 pt-12">
          { /* TODO: Pizza menu on mobile */ }
          <div className="grid md:grid-cols-2">
            <div>
              {showLogo && <Link to="/"><img src="/images/friendsies_header.png" className="md:h-16 h-12 -top-2 hidden md:block relative" alt="header" /></Link>}
            </div>
            <div className="flex justify-end">
              <a href="https://discord.gg/friendswithyou" target="_blank" rel="noreferrer">
                <img src={discordIcon} className="w-8 py-2 max-w-8 mr-2 text-white bg-gray-400 p-1 rounded-lg" alt="discord"/>
              </a>
              <a href="https://opensea.io/collection/friendsies" target="_blank" rel="noreferrer">
                <img src={openseaIcon} className="w-8 h-8 mr-2 text-white bg-gray-400 p-1 rounded-lg" alt="opensea"/>
              </a>
              <a href="https://twitter.com/fRiENDSiES_Ai" target="_blank" rel="noreferrer">
                <TwitterIcon className="h-8 w-8 mr-2 text-white bg-gray-400 p-1 rounded-lg"/>
              </a>
              <a href="https://www.instagram.com/friendswithyou/" target="_blank" rel="noreferrer">
                <InstagramIcon className="h-8 w-8 mr-2 text-white bg-gray-400 p-1 rounded-lg" />
              </a>

              {!signer && <button className="h-8 rounded-lg bg-gray-400 text-white px-2 py-1 font-bold" onClick={connectHandler}>Connect</button>}
              {signer && <button className="h-8 rounded-lg bg-gray-400 text-white px-2 py-1 font-bold" onClick={disconnectHandler}>Disconnect</button>}
            </div>
          </div>
          {showLogo && <Link to="/"><img src="/images/friendsies_header.png" className="mx-auto md:hidden" alt="header" /></Link>}
        </div>
      </header>
      <Outlet/>
      { /* TODO: Footer */ }
    </div>
  );
}

export default App;
