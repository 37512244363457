import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserSignature,
  rebuildFriendsie,
  userAccessSharePageAssets,
  userLeaveBuilder,
} from '../../state/user/userSlice';
import classes from '../MintWidget/MintWidget.module.css';
import { ReactComponent as TwitterIcon } from '../../assets/twitter-brands.svg'; //'./assets/twitter-brands.svg';
import { useNavigate } from 'react-router-dom';
import { checkForFriendsieRebuild } from '../../api/api';
import vectaryLinks from '../../vectary.json';

const ShareWidget = ({ signer, connectHandler, disconnectHandler, seedId }) => {
  const userTokens = useSelector((state) => state.user.userMintTokens);
  const userSignature = useSelector((state) => state.user.userSignature);
  const sharePageLinks = useSelector((state) => state.user.sharePageLinks);
  const buildButtonLoading = useSelector((state) => state.user.buildButtonLoading);
  const [userAddress, setUserAddress] = useState('');
  const [assetLinks, setAssetLinks] = useState(null);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  let tweetText = encodeURIComponent('I just minted my own customized fRiENDSiE! @fRiENDSiES_Ai @Friendswithyou');

  useEffect(() => {
    const getUserAddress = async () => {
      if (signer) {
        const userAddress = await signer.getAddress();
        setUserAddress(userAddress);
      } else {
      }
    };

    getUserAddress();
  }, [dispatch, signer, seedId]);

  // see if user can access assets for this friendsie
  useEffect(() => {
    const getUserAccessSharePage = async () => {
      if (userAddress && userSignature) {
        dispatch(userAccessSharePageAssets(userAddress, userSignature));
      }
    };

    getUserAccessSharePage();
  }, [dispatch, userAddress, userSignature]);

  //check if user owns this friendsie
  useEffect(() => {
    if (sharePageLinks && seedId in sharePageLinks) {
      setAssetLinks(sharePageLinks);
    }
  }, [seedId, sharePageLinks]);

  return (
    <>
      <div className="inset-x-auto mx-auto mb-1">
        {!signer && (
          <div className={`place-content-center items-center flex flex-col  rounded-full`}>
            <button
              className={`friendsie-pill rounded-br-xl rounded-bl-xl mb-2 px-2 py-1 font-bold`}
              onClick={connectHandler}
            >
              CONNECT WALLET
            </button>
          </div>
        )}

        {signer && (
          <div className={` place-content-center items-center flex flex-col rounded-full `}>
            <button
              className={`friendsie-pill rounded-br-xl rounded-bl-xl mb-2 px-2 py-1 font-bold  ${classes.connectWalletButton}`}
              onClick={disconnectHandler}
            >
              DISCONNECT
              <p className="text-l">
                0x{userAddress.toUpperCase().substring(2, 6)}...
                {userAddress.toUpperCase().substring(userAddress.length - 4, userAddress.length)}
              </p>
            </button>
          </div>
        )}
      </div>

      <div className={`${classes.mintWidgetContainer} md:p-1 lg:p-1 rounded-xl`}>
        <div className={`place-content-center items-center flex flex-col rounded-full p-4 align-middle`}>
          <p className="text-center text-fbuilderGreen font-Frankfurter align-middle mb-2">SHARE YOUR fRiENDSiE!</p>
          <div
            className={`${classes.tweetButton} rounded-full flex flex-row px-4 py-1 place-content-center items-center hover: cursor-pointer`}
          >
            <TwitterIcon className="h-8 w-8 mr-2 text-white  p-1 rounded-lg" />
            <a
              className="text-white text-xl text-center font-Frankfurter"
              href={`http://twitter.com/share?text=${tweetText}&url=https://friendsies.io/share/${seedId}&hashtags=fRiENDSiES`}
            >
              SHARE
            </a>
          </div>
        </div>
      </div>

      <div className={`${classes.mintWidgetContainer} mt-4 rounded-xl`}>
        <div className={`place-content-center items-center flex flex-col rounded-full align-middle`}>
          {!signer && (
            <div className="m-2">
              <p className="text-center text-fbuilderGreen font-Frankfurter align-middle mb-2 leading-5">{`Connect your wallet and sign message to access your assets`}</p>
            </div>
          )}
          {signer && assetLinks ? (
            <div className="m-2">
              <div className={`place-content-center items-center flex flex-col rounded-full align-middle mt-4`}>
                <p className="text-center text-fbuilderGreen font-Frankfurter align-middle mb-2 leading-5">{`Access your fRiENDSiES`}</p>

                <a
                  className={`${classes.mintButton} text-xs flex flex-row mb-2`}
                  href={assetLinks[seedId]?.glb_url}
                  alt="friendsie glb"
                  download
                >
                  DOWNLOAD GLB
                </a>

                <button
                  className={`${classes.mintButton} text-xs flex flex-row mb-2`}
                  onClick={() =>
                    navigate(`/ar/${assetLinks[seedId]?.vectary_key.replace('https://app.vectary.com/p/', '')}`)
                  }
                >
                  VIEW AR
                </button>

                <p className="text-center text-xs text-fbuilderGreen font-Frankfurter align-middle my-2">{`Private access, do not share URLs`}</p>
              </div>
            </div>
          ) : (
            <div className="m-2">
              <div className={`place-content-center items-center flex flex-col rounded-full align-middle`}>
                <p className="text-center text-fbuilderGreen font-Frankfurter align-middle mb-2 leading-5">{`You must own this friendsie to access assets`}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ShareWidget;

async function download(url) {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = await toDataURL(url);
  a.download = `download.jpg`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

function toDataURL(url) {
  try {
    return fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      })
      .catch((err) => {});
  } catch (error) {}
}
