import React from "react";
import { useParams } from "react-router-dom";
import vectaryLinks from "../vectary.json";

const Vectary = () => {
  const { link } = useParams();

  return (
    <div className="h-screen w-screen bg-white">
      {link ? (
        <iframe
          title="friendsie"
          src={`https://app.vectary.com/p/${link}`}
          frameBorder="0"
          width="100%"
          height="100%"
        ></iframe>
      ) : null}
    </div>
  );
};

export default Vectary;
