import React, { useEffect, useState } from 'react';
import DefaultImage from '../../assets/friendsie_default.png';
import UndoIcon from '../../assets/undo_icon.png';
import RandomIcon from '../../assets/random_icon.png';
import { createUrlFromState, traitDisplayName } from '../sceneutils';
import { useMediaQuery } from 'react-responsive';
import SproutCategory from '../../assets/images/sprout-category.png';
import FaceCategory from '../../assets/images/face-category.png';
import HeadCategory from '../../assets/images/head-category.png';
import BodyCategory from '../../assets/images/body-category.png';
import BackpieceCategory from '../../assets/images/backpiece-category.png';
import AccessoryCategory from '../../assets/images/accessory-category.png';
import ShoesCategory from '../../assets/images/shoes-category.png';
import classes from './TraitCategory.module.css';
import { useSelector } from 'react-redux';

export const TraitCategories = [
  { name: 'sprout', index: 0, image: SproutCategory },
  { name: 'face', index: 1, image: FaceCategory },
  { name: 'head', index: 2, image: HeadCategory },
  { name: 'body', index: 3, image: BodyCategory },
  { name: 'backpiece', index: 4, image: BackpieceCategory },
  { name: 'accessory', index: 5, image: AccessoryCategory },
  { name: 'shoe', index: 6, image: ShoesCategory },
];

const Index = ({
  traitCategory,
  friendsieState,
  previousFriendsieState,
  undo,
  randomFriendsie,
  changeTraitCategory,
  fullScreenMode,
  goFullScreen,
  closeScreen
}) => {
  const disableUndoTimer = useSelector((state) => state.user.disableUndoTimer);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      {/* DESKTOP AND TABLET */}
      <div className="hidden z-50  md:block fixed md:left-2 lg:left-28  md:top-24 lg:top-28">
        <div className="rounded-xl bg-opacity-75 bg-fbluetrait p-3 md:top-2 lg: inset-y-auto align-middle">
          {TraitCategories.map((traitData, index) => {
            return (
              <div
                key={index}
                className={`place-content-center items-center flex flex-col mb-0 rounded-full px-2 py-2 ${
                  traitCategory === traitData.name ? 'glowing-outline' : 'border-transparent'
                }`}
                onClick={() => {
                  changeTraitCategory(traitData);
                }}>
                <div
                  className={`${
                    (traitData.name === 'face' && friendsieState.headFixed === 'fixed') ||
                    (traitData.name === 'sprout' && friendsieState.headNoSprout) ||
                    (friendsieState.fixedModel && traitData.name !== 'head')
                      ? ''
                      : classes.scaleOnHover
                  } place-content-center items-center flex flex-col`}>
                  <img
                    className={`object-scale-down h-7 place-content-center ${
                      (traitData.name === 'face' && friendsieState.headFixed === 'fixed') ||
                      (traitData.name === 'sprout' && friendsieState.headNoSprout) ||
                      (friendsieState.fixedModel && traitData.name !== 'head')
                        ? `cursor-default ${classes.grayScale}`
                        : 'cursor-pointer '
                    } select-none`}
                    alt="trait"
                    src={traitData.image}></img>
                  {/* <button className="shadow-md py-1 w-16 block text-fgreen bg-fyellow rounded-full font-bold select-none text-center text-xs">
              {traitData.name}
            </button> */}
                  <button
                    className={`friendsie-pill${
                      (traitData.name === 'face' && friendsieState.headFixed === 'fixed') ||
                      (traitData.name === 'sprout' && friendsieState.headNoSprout) ||
                      (friendsieState.fixedModel && traitData.name !== 'head')
                        ? '-disabled'
                        : ''
                    }`}>
                    {traitDisplayName(traitData.name)}
                  </button>
                </div>
              </div>
            );
          })}

          {/* Put random/ undo in trait category column on tablet */}
          <div className="hidden md:block lg:hidden">
            <div
              className={`place-content-center items-center flex flex-col mb-0 rounded-full px-2 py-1 ${'border-transparent'}`}
              onClick={() => {
                undo();
              }}>
              <div className={`${previousFriendsieState && disableUndoTimer > 0 ? classes.scaleOnHover : ''} place-content-center items-center flex flex-col`}>
                <img
                  className={` object-scale-down h-10 mb-1 place-content-center ${
                    previousFriendsieState && disableUndoTimer > 0 ? 'cursor-pointer' : `cursor-default ${classes.grayScale}`
                  }
             select-none`}
                  alt="trait"
                  src={UndoIcon}></img>
                {/* <button className="shadow-md py-1 w-16 block text-fgreen bg-fyellow rounded-full font-bold select-none text-center text-xs">
              {traitData.name}
            </button> */}
                <button className={` friendsie-pill${previousFriendsieState && disableUndoTimer > 0 ? '' : '-disabled'}`}>{`UNDO`}</button>
              </div>
            </div>
            <div
              className={` place-content-center items-center flex flex-col mb-0 rounded-full px-2 py-1 ${'border-transparent'}`}
              onClick={() => {
                randomFriendsie();
              }}>
              <div className={`${classes.scaleOnHover} place-content-center items-center flex flex-col`}>
                <img className={` object-scale-down h-10 mb-1 place-content-center cursor-pointer select-none`} alt="trait" src={RandomIcon}></img>
                {/* <button className="shadow-md py-1 w-16 block text-fgreen bg-fyellow rounded-full font-bold select-none text-center text-xs">
              {traitData.name}
            </button> */}
                <button className={`friendsie-pill`}>{`RANDOM`}</button>
              </div>
            </div>
            <div className="hidden md:block lg:hidden mt-4">
              <button onClick={fullScreenMode ? closeScreen : goFullScreen} className="friendsie-pill m-0 p-0">
                {fullScreenMode ? 'EXIT FULLSCREEN' : 'FULLSCREEN'}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* MOBILE ONLY  */}
      <div className="md:hidden fixed bottom-24 place-items-center m-1 w-full">
        <div className="flex flex-row place-content-center bg-fbluetrait rounded-t-xl pb-24 -mb-24">
          <div className="grid grid-cols-7 px-1">
            {TraitCategories.map((traitData, index) => {
              return (
                <div
                  key={index}
                  className={`place-content-center items-center flex flex-col mb-0 rounded-full px-2 py-3 ${
                    traitCategory === traitData.name ? 'glowing-outline' : 'border-transparent'
                  }`}
                  onClick={() => {
                    changeTraitCategory(traitData);
                  }}>
                  <img
                    className={`object-scale-down h-8 place-content-center ${
                      (traitData.name === 'face' && friendsieState.headFixed === 'fixed') ||
                      (traitData.name === 'sprout' && friendsieState.headNoSprout) ||
                      (friendsieState.fixedModel && traitData.name !== 'head')
                        ? `cursor-default ${classes.grayScale}`
                        : 'cursor-pointer '
                    } select-none`}
                    alt="trait"
                    src={traitData.image}></img>

                  <button
                    className={`font-builder   text-xs ${
                      (traitData.name === 'face' && friendsieState.headFixed === 'fixed') ||
                      (traitData.name === 'sprout' && friendsieState.headNoSprout) ||
                      (friendsieState.fixedModel && traitData.name !== 'head')
                        ? 'text-gray-400 opacity-50'
                        : 'text-faccentbluetext'
                    }`}>
                    {traitDisplayName(traitData.name)}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/*  MOBILE ONLY */}
      <div className="md:hidden p-2 rounded-xl  z-10 fixed left-2 top-20  bg-opacity-75 bg-fbluetrait">
        <div
          className={`place-content-center items-center flex flex-col mb-0 rounded-full  border-transparent`}
          onClick={() => {
            undo();
          }}>
          <img
            className={`mb-1 h-12 place-content-center ${previousFriendsieState ? 'cursor-pointer' : 'cursor-default'} select-none`}
            alt="trait"
            src={UndoIcon}></img>
          <button className={`friendsie-pill${previousFriendsieState ? '' : '-disabled'}`}>{`UNDO`}</button>
        </div>
      </div>

      <div className="md:hidden p-2 rounded-xl z-10 fixed right-2 top-20  bg-opacity-75 bg-fbluetrait">
        <div
          className={`place-content-center items-center flex flex-col mb-0 rounded-full  border-transparent`}
          onClick={() => {
            randomFriendsie();
          }}>
          <img className={`mb-1 h-12 place-content-center cursor-pointer select-none`} alt="trait" src={RandomIcon}></img>
          <button className={`friendsie-pill`}>{`RANDOM`}</button>
        </div>
      </div>
    </>
  );
};

export default Index;
