import { Stats, useProgress } from '@react-three/drei';
import { ethers } from 'ethers';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveUsers } from '../../api/api';
import { FriendsiesContract } from '../../contracts/Friendsies';
import { setBuilderState, setFriendsieReservedTimer, userLeaveBuilder } from '../../state/user/userSlice';
import { setIntervalImmediately } from '../MintWidget';
import classes from '../MintWidget/MintWidget.module.css';

const web3Provider = new ethers.providers.AlchemyProvider(process.env.REACT_APP_NETWORK, process.env.REACT_APP_ALCHEMY_API_KEY);

const BuilderWidget = ({ build }) => {
  const userTokens = useSelector((state) => state.user.userMintTokens);
  const reduxFriendsieReservedTimer = useSelector((state) => state.user.friendsieReservedTimer);
  const disableFinishBuild = useSelector((state) => state.user.disableFinishBuild);
  const dispatch = useDispatch();

  // threejs scene states
  const { active } = useProgress();

  // create new timer everytime dutch auction price decreases
  useEffect(() => {
    let friendsieReserveInterval = setInterval(() => {
      if (reduxFriendsieReservedTimer > 0) {
        // would be better to keep this in local state, but timer needs to be called from seeds/update easier to make it globally available
        // component wont retrigger if redux store value always set to the same amount (in this case, we're resetting it to 600 seconds everytime, so component doesnt re-render)
        dispatch(setFriendsieReservedTimer(reduxFriendsieReservedTimer - 1));
      } else {
        clearInterval(friendsieReserveInterval);
      }
    }, 1000);
    return () => {
      clearInterval(friendsieReserveInterval);
    };
  }, [reduxFriendsieReservedTimer, dispatch]);

  return (
    <div className={`${classes.mintWidgetContainer} md:p-1 lg:p-1 mt-2 rounded-xl`}>
      <div className={`place-content-center items-center flex flex-col rounded-full`}>
        <div className="-mt-4">
          <button className="friendsie-pill mb-2">BUILDING</button>
        </div>
        <div className={` place-content-center items-center flex  font-FredokaOne`}>
          <p className="text-center text-fbuilderGreen font-FredokaOne">Building fRiENDSiES</p>
        </div>

        {/* User tokens */}
        {userTokens && userTokens.pending_tokens && userTokens.tokens && (
          <div>
            <div className="bg-fbuilderGreen rounded-md py-1 relative px-2 mb-2">
              <div>
                <p className=" text-fbuilderyellow font-FredokaOne ">{`${userTokens.tokens.length + 1} of ${
                  userTokens.tokens.length + userTokens.pending_tokens.length
                }`}</p>
              </div>
            </div>
          </div>
        )}

        {/* Friendsie Reserved Countdown Timer */}
        <div>
          <div className=" py-1 relative px-2 mb-1">
            <div>
              {reduxFriendsieReservedTimer > 0 ? (
                <p className=" text-fbuilderGreen font-FredokaOne text-center">{`fRiENDSiE reserved for ${new Date(reduxFriendsieReservedTimer * 1000)
                  .toISOString()
                  .slice(14, 19)}`}</p>
              ) : (
                <p className=" text-fbuilderGreen font-FredokaOne text-center">{`Your fRiENDSiE is no longer reserved.`}</p>
              )}
            </div>
          </div>
        </div>

        {/* Finish Button*/}
        <div className={` place-content-center items-center flex  -mb-4 font-FredokaOne text-sm `}>
          <button disabled={disableFinishBuild} onClick={build} className={`${classes.mintButton}`}>
            FINISH
          </button>
        </div>
      </div>
    </div>
  );
};

export default BuilderWidget;
