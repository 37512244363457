import React, { useEffect, useState } from 'react';
import classes from '../MintWidget/MintWidget.module.css';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { getUserSignature, userEnterBuilder } from '../../state/user/userSlice';
import { useCookies } from 'react-cookie';
import { ethers } from 'ethers';
import { FriendsiesContract } from '../../contracts/Friendsies.js';
import { setIntervalImmediately } from '../MintWidget';
import { toast } from 'react-toastify';

const web3Provider = new ethers.providers.AlchemyProvider(process.env.REACT_APP_NETWORK, process.env.REACT_APP_ALCHEMY_API_KEY);

const mintQuantityOptions = [1, 2, 3, 4, 5];

const DutchAuction = ({ signer, connectHandler, disconnectHandler }) => {
  const userAuth = useSelector((state) => state.user.userAuthToken);
  const userTokens = useSelector((state) => state.user.userMintTokens);
  const builderState = useSelector((state) => state.user.builderState);
  const ghostCloudAllowlist = useSelector((state) => state.user.ghostCloudAllowlist);
  const superGoldenCloudAllowlist = useSelector((state) => state.user.superGoldenCloudAllowlist);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [mintQuantity, setMintQuantity] = useState(1);
  const [currentDutchAuctionPrice, setCurrentDutchAuctionPrice] = useState(null);
  const [isMinting, setMinting] = useState(false);
  const [currentSeconds, setCurrentSeconds] = useState(0);
  const [currentBlock, setCurrentBlock] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [dutchAuctionAvailableSupply, setDutchAuctionAvailableSupply] = useState(0);

  const dispatch = useDispatch();

  // get price every 5 seconds
  useEffect(() => {
    const interval = setIntervalImmediately(async () => {
      const currentDutchAuctionState = await FriendsiesContract(web3Provider).dutchAuctionsActive();
      if (currentDutchAuctionState) {
        const blockLeft = await FriendsiesContract(web3Provider).dutchAuctionsNextDecrease();
        const y = (await FriendsiesContract(web3Provider).dutchAuctionsCurrentPrice());
        const supplyAvailable = await FriendsiesContract(web3Provider).dutchAuctionsSupply();

        setCurrentDutchAuctionPrice(y);
        setCurrentBlock(blockLeft);
        setCurrentSeconds(blockLeft * 13);
        setDutchAuctionAvailableSupply(supplyAvailable);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  // create new timer everytime dutch auction price decreases
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (currentSeconds > 0) {
        setCurrentSeconds(currentSeconds - 1);
      } else {
        clearInterval(myInterval);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [currentBlock, currentSeconds]);

  const mint = async () => {
    if(!signer) {
      toast.error(`Please connect your wallet`);
      return
    }

    setErrorMessage('');
    setMinting(true);

    let tx;
    const currentPrice = (await FriendsiesContract(web3Provider).dutchAuctionsCurrentPrice());

    try {
      tx = await FriendsiesContract(signer).safeMint(mintQuantity, { value: currentPrice.mul(mintQuantity) });
    } catch (error) {
      setMinting(false);
      // console.log('minting error: ', error);
      if (error.message.includes('execution reverted')) {
        toast.error(`${error.message?.replace('execution reverted: ', '').replace('MetaMask Tx Signature: ', '') ?? 'Minting failed!'}`);
      } else if (error.message.includes('insufficient funds')) {
        toast.error(`Insufficient funds for transaction`);
      }
      // setErrorMessage(error.message?.replace('execution reverted: ', '').replace('MetaMask Tx Signature: ', '') ?? 'Minting failed!');
      return;
    }

    try {
      await tx.wait();
      setMinting(false);
    } catch (error) {
      // catch ({ error }) {
      setMinting(false);
      // console.log('transaction error: ', error);
      // setErrorMessage(error.message?.rep
      // setErrorMessage(error.message?.replace('execution reverted: ', '').replace('MetaMask Tx Signature: ', '') ?? 'Minting failed!');
      return;
    }

    setErrorMessage(null);
  };

  return (
    <>
      {/* DESKTOP AND TABLET */}
      {builderState === 'preview' && (
        <div>
          <div className={`place-content-center items-center flex flex-col mb-0 mt-2 rounded-full px-4 ${'border-transparent'}`}>
            {/* Quantity  widget */}
            <div className={`place-content-center items-center flex flex-col mt-1`}>
              <div className="text-center text-fbuilderGreen font-FredokaOne leading-4">
                <p>
                  {8765 - dutchAuctionAvailableSupply} / 8765 <br /> MINTED
                </p>
              </div>
            </div>

            {/* price widget */}
            <div className="grid grid-cols-2 place-content-center items-center mt-2">
              <div className="px-2">
                <p className="text-center text-fbuilderGreen font-FredokaOne text-xs">
                  CURRENT <br />
                  PRICE
                </p>
              </div>
              <div className="px-3">
                {currentDutchAuctionPrice && (
                  <div className="bg-fbuilderGreen rounded-md py-1 relative">
                    <p className="text-center text-fbuilderyellow font-FredokaOne  text-lg pb-1">
                      {currentDutchAuctionPrice ? ethers.utils.formatUnits(currentDutchAuctionPrice, 'ether') : '0'}
                    </p>
                    <span className={`absolute bottom-0 text-fbuilderyellow font-FredokaOne  left-1/2 transform -translate-x-1/2 ${classes.ethText}`}>ETH</span>
                  </div>
                )}
              </div>
            </div>

            {/* Timer widget */}
            <div className={`place-content-center items-center flex flex-col mt-2`}>
              <button className={`hidden md:hidden lg:block friendsie-pill rounded-br-xl rounded-bl-xl font-bold ${classes.nextEntryButton}`}>
                NEXT ENTRY
              </button>
              <div className="text-center text-fbuilderGreen font-FredokaOne text-lg">
                <p>{currentSeconds ? new Date(currentSeconds * 1000).toISOString().slice(11, 19) : '00:00:00'}</p>
              </div>
            </div>

            {/* Quantity widget */}
            <div className={`place-content-center items-center relative w-full mt-2 mx-3  rounded-xl text-fbuilderyellow font-FredokaOne text-sm  mb-2 `}>
              <div className="grid grid-cols-5 w-full text-center ">
                {mintQuantityOptions.map((quantity, index) => (
                  <div
                    key={index}
                    className={`${index === 0 && 'rounded-l-lg'} ${index === mintQuantityOptions.length - 1 && 'rounded-r-lg'} ${
                      mintQuantity === quantity ? 'bg-fbuilderyellow text-fbuilderGreen' : 'bg-fbuilderGreen'
                    } ${classes.dutchAuctionQuantityContainer} hover: cursor-pointer`}
                    onClick={() => setMintQuantity(quantity)}>
                    {quantity}
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Mint Button*/}
          <div
            className={`place-content-center items-center flex  font-FredokaOne text-sm ${
              ghostCloudAllowlist || superGoldenCloudAllowlist ? '' : '-mb-4'
            } mt-2`}>
            <button onClick={() => mint()} className={`${classes.mintButton} flex flex-row`}>
              {isMinting && (
                <svg className="animate-spin h-5 w-5 mr-3 text-white" viewBox="0 0 24 24">
                  {/* <circle className="opacity-25" cx="12" cy="12" r="10" stroke="text-white" stroke-width="4"></circle> */}
                  <path
                    className="opacity-75 text-white"
                    fill="#0dbd00"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              )}
              {isMinting ? 'MINTING' : 'MINT'}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default DutchAuction;
