import { memo, Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { Stats, PresentationControls, useProgress, OrbitControls, Loader, Environment, useTexture, useGLTF } from '@react-three/drei';
import * as THREE from 'three';
import FriendiseModel from '../builder_components/Friendsies/FriendiseModel';
import World from '../builder_components/Environment/World';

import data from '../data.json';
import axios from 'axios';
import { defaultApiData } from '../DEFAULTapiData';
import FrengenImg from '../assets/FrengenLogo1.png';
import { useMediaQuery } from 'react-responsive';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js';
import { createModelFromShare, fetchInitialData, undoSeed } from '../api/api';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import EnterQueue from '../components/EnterQueue';
import classes from '../builder_components/FriendsieScene.module.css';
import LoadingCloudModel from '../builder_components/Friendsies/LoadingCloudModel';

import ShareWidget from '../components/ShareWidget';
import { Leva } from 'leva';
import { getUserTokens } from '../state/user/userSlice';
import { createUrlFromState } from '../builder_components/sceneutils';

const mockObject = new THREE.Object3D();

const Share = ({ signer, connectHandler, disconnectHandler }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const lookAtPos = new THREE.Vector3(0, isMobile ? 0.05 : 0.15, 0);

  // builder states
  const [friendsieState, setFriendsieState] = useState(null);
  const globalAssets = useSelector((state) => state.assets.assets)

  const [downloadingModel, setDownloadingModel] = useState(false);
  const [entireScene, setEntireScene] = useState(null);

  const friendsieTokenId = useSelector((state) => state.user.friendsieTokenId);
  const builderState = useSelector((state) => state.user.builderState);

  // threejs scene states
  const { active, progress, loaded } = useProgress();

  const { seedId } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();

  // Inital app load data. if user is in preview, call this, and don't load data from websocket
  useEffect(() => {
    createModelFromShare(seedId, setFriendsieState);

    // eslint-disable-next-line
  }, []);

  const navigate = useNavigate();

  // if user decides to rebuild their friendsie tokenId, builderState will change to `building` while they're on the share page
  // this useEffect will trigger and redirect them to the builder, and the builder will check the authToken in redux and restore the seed
  useEffect(() => {
    const rebuildFriendsie = async () => {
      if (builderState === 'building') {
        navigate(`/builder`);
      }
    }

    rebuildFriendsie()
  }, [builderState, navigate, seedId]);

  var elem = document.documentElement;

  const [fullScreenMode, setFullScreenMode] = useState(false);

  document.addEventListener('fullscreenchange', (event) => {
    if (document.fullscreenElement || document.webkitFullscreenElement) {
      setFullScreenMode(true);
    } else {
      setFullScreenMode(false);
    }
  });

  const goFullScreen = () => {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  };
  const closeScreen = async () => {
    if (!document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  };

  // model ref for download
  const modelDownloadMesh = useRef();
  const [envMapState, setEnvMapState] = useState('empty_warehouse_01_1k.hdr');

  return (
    <div className="overflow-hidden h-screen w-screen touch-none">
      <div className={`${loaded ? 'opacity-100' : 'opacity-0'}  overflow-hidden touch-none ${classes.builderCanvas}`}>
        <Canvas
          shadows
          flat
          linear
          mode="concurrent"
          camera={{ near: 0.01, far: 100 }}
          gl={{
            powerPreference: 'high-performance',
            // preserveDrawingBuffer: true,
            // alpha: true,
            antialias: true,
            // outputEncoding: THREE.sRGBEncoding,
            physicallyCorrectLights: true,
          }}
          onCreated={({ camera }) => {
            camera.position.x = 0;
            camera.position.y = isMobile ? 0.05 : 0.075;
            camera.position.z = isMobile ? 0.65 : 0.55;
            camera.lookAt(lookAtPos);
            camera.updateProjectionMatrix();
            // camera.fov =
          }}>
          {/* <OrbitControls
          target={[0, 0.1, 0]}
          enablePan={false}
          enableDamping={true}
          minAzimuthAngle={-Math.PI * 0.1}
          maxAzimuthAngle={Math.PI * 0.1}
          maxPolarAngle={Math.PI * 0.5}
          minPolarAngle={Math.PI / 2.33}
          maxDistance={0.5}
          minDistance={0.25}
        /> */}

          <Suspense fallback={null}>
            <World />
            <Environment
              files={`/envMaps/${envMapState}`}

              // preset={envMapState}
            />
            {/* <Fireflies /> */}
            <LoadingCloudModel />
          </Suspense>
          <fog attach="fog" args={[new THREE.Color(0xffffff), 1, 7]} />
          <Suspense fallback={null}>
            <PresentationControls global polar={[0, 0]} speed={3}>
              {friendsieState && (
                <FriendiseModel
                  friendsieState={friendsieState}
                  mockObject={mockObject}
                  downloadingModel={downloadingModel}
                  setEntireScene={setEntireScene}
                  // setRendererInfo={setRendererInfo}
                  ref={modelDownloadMesh}
                />
              )}
            </PresentationControls>
          </Suspense>
        </Canvas>
        {/* 
      <CongratulationsModal friendsieState={friendsieState} /> */}

        {loaded && globalAssets && friendsieState && (
          <>
            {/* Frengen Logo */}
            <div className={`${classes.frengenLogoContainer}`}>
              <img draggable={false} className={`${classes.frengenLogoPainInMyAss}`} src={FrengenImg} alt="frengen" />
              {builderState === 'share' && friendsieTokenId && (
                <div className={`${classes.mintingFriendsieModal}  rounded-lg absolute mt-4 text-center p-3`}>
                  <p className="text-fbuilderGreen font-FredokaOne leading-5">{`fRiENDSiES token #${friendsieTokenId} is completed! It will be finalized on-chain and visible on OpenSea with the public reveal!`}</p>
                </div>
              )}
            </div>

            {/* Full screen button */}
            <div className="hidden lg:block fixed bottom-0 right-0 m-2">
              <button onClick={fullScreenMode ? closeScreen : goFullScreen} className="friendsie-pill m-0 p-0">
                {fullScreenMode ? 'EXIT FULLSCREEN' : 'FULLSCREEN'}
              </button>
            </div>

            <div className={`hidden  md:block right-2 top-2 z-10 fixed`}>
              <ShareWidget
                signer={signer}
                connectHandler={connectHandler}
                disconnectHandler={disconnectHandler}
                seedId={seedId}
                // resetWebSocketAssets={resetWebSocketAssets}
              />
            </div>

            <></>
          </>
        )}
        {/* <Leva hidden /> */}
      </div>
    </div>
  );
};

export default Share;
