import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/userSlice';
import assetsReducer from './assets/assetsSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    assets: assetsReducer
  },
});
