import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FriendsiesContract } from "../../contracts/Friendsies";
import {
  getGhostCloudAllowlist,
  getSuperGoldenCloudAllowlist,
  getUserSignature,
  getUserTokens,
  setBuilderState,
  setClaimedGhostCloud,
  setClaimedSuperGoldenCloud,
  setUserAuthToken,
  userEnterBuilder,
} from "../../state/user/userSlice";
import BuilderWidget from "../BuilderWidget";
import DutchAuction from "../DutchAuction";
import GhostCloud from "../GhostCloud";
import Stats from "../Stats";
import SuperGoldenCloud from "../SuperGoldenCloud";
import classes from "./MintWidget.module.css";

const web3Provider = new ethers.providers.AlchemyProvider(
  process.env.REACT_APP_NETWORK,
  process.env.REACT_APP_ALCHEMY_API_KEY
);

export const setIntervalImmediately = (fn, interval) => {
  fn();
  return setInterval(fn, interval);
};

const Mint = ({ signer, connectHandler, disconnectHandler, build }) => {
  const [dutchAuctionsActive, setDutchAuctionsActive] = useState(false);
  const [ghostCloudActive, setGhostCloudActive] = useState(false);
  const [superGoldenCloudActive, setSuperGoldenCloudActive] = useState(false);
  const [publicMintTime, setPublicMintTime] = useState(
    Math.abs(
      Math.floor(
        (new Date("Saturday, April 2, 2022 12:00:00 PM GMT-04:00") -
          new Date()) /
          1000
      )
    )
  ); // Saturday, April 2, 2022 12:00:00 PM GMT-04:00

  const [publicDateTime, setPublicDateTime] = useState(null);
  const [userAddress, setUserAddress] = useState("");
  const [viewClaim, setViewClaim] = useState(false);

  const builderState = useSelector((state) => state.user.builderState);
  const userAuth = useSelector((state) => state.user.userAuthToken);
  const userTokens = useSelector((state) => state.user.userMintTokens);

  const ghostCloudAllowlist = useSelector(
    (state) => state.user.ghostCloudAllowlist
  );
  const superGoldenCloudAllowlist = useSelector(
    (state) => state.user.superGoldenCloudAllowlist
  );
  const claimedGhostCloud = useSelector(
    (state) => state.user.claimedGhostCloud
  );
  const claimedSuperGoldenCloud = useSelector(
    (state) => state.user.claimedSuperGoldenCloud
  );
  const christiesAuctionWinnerError = useSelector(
    (state) => state.user.christiesAuctionWinnerError
  );

  const dispatch = useDispatch();

  // check if wallet has auth token in localstorage
  useEffect(() => {
    const checkLocalStorageAuth = async () => {
      if (signer) {
        const userAddress = await signer.getAddress();
        setUserAddress(userAddress);

        if (userAddress) {
          let localStorageItem = localStorage.getItem(userAddress);
          if (localStorageItem) {
            dispatch(setBuilderState("building"));
            dispatch(setUserAuthToken(localStorageItem));
          }
        }
      }
    };

    checkLocalStorageAuth();
  }, [dispatch, signer]);

  // create new timer everytime dutch auction price decreases
  useEffect(() => {
    let publicSaleInterval = setInterval(() => {
      if (publicMintTime > 0) {
        setPublicMintTime(publicMintTime - 1);
      } else {
        clearInterval(publicSaleInterval);
      }
    }, 1000);
    return () => {
      clearInterval(publicSaleInterval);
    };
  }, [publicMintTime]);

  const formatDateTime = (seconds) => {
    var days = Math.floor(seconds / (24 * 60 * 60));
    var leftSec = seconds - days * 24 * 60 * 60;

    var hrs = Math.floor(leftSec / (60 * 60));

    return `${days} day${days > 1 || days === 0 ? "s" : ""} ${hrs} hours`;
  };

  // Get the state of the contract -- poll state every 10 seconds
  useEffect(() => {
    const interval = setIntervalImmediately(async () => {
      const currentDutchAuctionState = await FriendsiesContract(
        web3Provider
      ).dutchAuctionsActive();
      const currentGhostCloudState = await FriendsiesContract(
        web3Provider
      ).ghostCloudActive();
      const currentSuperGoldenCloudState = await FriendsiesContract(
        web3Provider
      ).superGoldenCloudActive();
      setDutchAuctionsActive(currentDutchAuctionState);
      setGhostCloudActive(currentGhostCloudState);
      setSuperGoldenCloudActive(currentSuperGoldenCloudState);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  // try to get new user tokens every 5 seconds
  useEffect(() => {
    const interval = setIntervalImmediately(async () => {
      if (signer) {
        if (ghostCloudActive) {
          dispatch(getGhostCloudAllowlist(signer));
        }

        if (superGoldenCloudActive) {
          dispatch(getSuperGoldenCloudAllowlist(signer));
        }

        dispatch(getUserTokens(signer));
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [signer, dispatch, ghostCloudActive, superGoldenCloudActive]);

  useEffect(() => {
    const checkClaimStatus = async () => {
      let ghostClaimState;
      let goldenClaimState;

      if (signer) {
        const userAddress = await signer.getAddress();
        // on ghost cloud allowlist - did they claim?
        if (ghostCloudAllowlist) {
          ghostClaimState = await FriendsiesContract(
            web3Provider
          ).hasClaimedGhostCloud(
            userAddress,
            ghostCloudAllowlist.amount,
            ghostCloudAllowlist.price
          );
          dispatch(setClaimedGhostCloud(ghostClaimState));
        }

        // on super golden cloud allowlist - did they claim?
        if (superGoldenCloudAllowlist) {
          goldenClaimState = await FriendsiesContract(
            web3Provider
          ).hasClaimedSuperGoldenCloud(
            userAddress,
            superGoldenCloudAllowlist.amount,
            superGoldenCloudAllowlist.price
          );
          dispatch(setClaimedSuperGoldenCloud(goldenClaimState));
        }

        let claimGhostCloudAvailable =
          ghostCloudAllowlist &&
          ghostClaimState !== null &&
          ghostClaimState !== true;
        let claimSuperGoldenAvailable =
          superGoldenCloudAllowlist &&
          goldenClaimState !== null &&
          goldenClaimState !== true;
        setViewClaim(
          signer && (claimGhostCloudAvailable || claimSuperGoldenAvailable)
        );
      }
    };

    checkClaimStatus();
  }, [superGoldenCloudAllowlist, ghostCloudAllowlist, signer, dispatch]);

  return (
    <div className="">
      {/* <div className="inset-x-auto mx-auto mb-1">
        {!signer && (
          <div className={`place-content-center items-center flex flex-col  rounded-full`}>
            <button className={`friendsie-pill rounded-br-xl rounded-bl-xl mb-4 px-2 py-1 font-bold`} onClick={connectHandler}>
              CONNECT WALLET
            </button>
          </div>
        )}

        {signer && (
          <div className={` place-content-center items-center flex flex-col rounded-full `}>
            <button
              className={`friendsie-pill rounded-br-xl rounded-bl-xl mb-4 px-2 py-1 font-bold  ${classes.connectWalletButton}`}
              onClick={disconnectHandler}>
              DISCONNECT
              <p className="text-l">0x{userAddress.toUpperCase().substring(2, 6)}...{userAddress.toUpperCase().substring(userAddress.length-4,userAddress.length)}</p>
            </button>
          </div>
        )}
      </div> */}

      {/* At least dutch, ghost, or golden is active */}
      {builderState === "preview" && viewClaim && (
        <div
          className={`${classes.mintWidgetContainer} md:p-1 lg:p-1 rounded-xl`}
        >
          {dutchAuctionsActive && (
            <DutchAuction
              signer={signer}
              connectHandler={connectHandler}
              disconnectHandler={disconnectHandler}
            />
          )}

          {/* Either on ghost cloud or golden cloud, or both */}
          {(ghostCloudAllowlist || superGoldenCloudAllowlist) &&
            (superGoldenCloudActive || ghostCloudActive) && (
              <div
                className={`${
                  ghostCloudAllowlist || superGoldenCloudAllowlist
                    ? "mt-2"
                    : "mt-8"
                }`}
              >
                {dutchAuctionsActive && (
                  <hr className="border-fbuilderGreen opacity-30 w-3/4 mx-auto" />
                )}

                <div
                  className={`${
                    ghostCloudAllowlist && superGoldenCloudAllowlist
                      ? "flex-row"
                      : "flex-col"
                  } flex mb-2 place-content-center items-center`}
                >
                  {ghostCloudActive &&
                    ghostCloudAllowlist &&
                    !claimedGhostCloud && (
                      <GhostCloud
                        signer={signer}
                        connectHandler={connectHandler}
                        disconnectHandler={disconnectHandler}
                      />
                    )}
                  {superGoldenCloudActive &&
                    superGoldenCloudAllowlist &&
                    !claimedSuperGoldenCloud && (
                      <SuperGoldenCloud
                        signer={signer}
                        connectHandler={connectHandler}
                        disconnectHandler={disconnectHandler}
                      />
                    )}
                </div>
              </div>
            )}
        </div>
      )}

      {/* Wallet Connected. Four states:
          0. no tokens
          1. no tokens, waiting dutch auction
          2. has special token, ready to build
          3. has normal token, ready to build
      */}
      {builderState === "preview" && signer && (
        <div
          className={`${classes.mintWidgetContainer} md:p-1 mt-4 rounded-xl`}
        >
          {userTokens.pending_tokens &&
            userTokens.pending_tokens.length > 0 &&
            !userAuth && (
              <>
                {christiesAuctionWinnerError && (
                  <span>
                    <p className="text-center text-fbuilderGreen font-FredokaOne mt-1">{`Congrats on your ultra special FRIENDSIE.`}</p>
                    <p className="text-center text-fbuilderGreen font-FredokaOne mt-1">
                      {`Your build starts in ${christiesAuctionWinnerError
                        .split(" ")
                        .pop()}`}
                    </p>
                  </span>
                )}

                {!christiesAuctionWinnerError &&
                  userTokens.pending_tokens &&
                  userTokens.pending_tokens.length > 0 && (
                    <p className="text-center text-fbuilderGreen font-FredokaOne mt-1">
                      {userTokens.pending_tokens.length} FRIENDSIES <br />
                      READY TO BUILD!
                    </p>
                  )}

                <div
                  className={`mt-1 place-content-center items-center flex flex-col rounded-full -mb-4`}
                >
                  <button
                    onClick={() => dispatch(getUserSignature(signer))}
                    className={`text-xs ${classes.mintButton}`}
                  >
                    SIGN IN TO
                    <br />
                    START BUILDING
                  </button>
                </div>
              </>
            )}

          {userTokens.tokens &&
            userTokens.tokens.length > 0 &&
            !(
              userTokens.pending_tokens ||
              userTokens.pending_tokens.length === 0
            ) && (
              <span>
                <p className="text-center text-fbuilderGreen font-FredokaOne mt-1 p-1">
                  {`{userTokens.tokens.length} FRIENDSIES`}
                  <br />
                  ALREADY BUILT
                </p>
              </span>
            )}

          {(!userTokens.pending_tokens ||
            userTokens.pending_tokens.length === 0) && (
            <span>
              <p className="text-center text-fbuilderGreen font-FredokaOne mt-1 p-1">{`You have no FRIENDSIES available to build.`}</p>
              <p className="text-center text-fbuilderGreen font-FredokaOne mb-2 mt-1">
                Please claim or purchase on{" "}
                <a
                  href="https://opensea.io/collection/friendsies?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Build%20Status&search[stringTraits][0][values][0]=pending"
                  className="underline"
                  rel="noreferrer"
                  target="_blank"
                >
                  OpenSea
                </a>
                , then enter the builder to customize your NFTs.
              </p>
            </span>
          )}
        </div>
      )}

      {/* {builderState === "preview" && (
        <div
          className={`${classes.mintWidgetContainer} md:p-2 mt-8 rounded-xl`}
        >
          <p className="text-center text-red-500 font-FredokaOne mb-2 mt-1">
            You are in preview mode.
          </p>
          {!signer && (
            <p className="text-center text-fbuilderGreen font-FredokaOne mb-2 mt-1">
              Please connect your wallet to continue.
            </p>
          )}
        </div>
      )} */}

      {builderState === "building" && userAuth && (
        <>
          <BuilderWidget build={build} />
        </>
      )}
    </div>
  );
};

export default Mint;
