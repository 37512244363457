import { Environment, PresentationControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import React, { forwardRef, memo, Suspense } from "react";
import { useMediaQuery } from "react-responsive";
import World from "./Environment/World";
import FriendiseModel from "./Friendsies/FriendiseModel";
import LoadingCloudModel from "./Friendsies/LoadingCloudModel";
import * as THREE from "three";

const CanvasComponent = (
  { friendsieState, mockObject, downloadingModel, setEntireScene },
  ref
) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const lookAtPos = new THREE.Vector3(0, isMobile ? 0.05 : 0.15, 0);

  return (
    <Canvas
      shadows
      flat
      linear
      mode="concurrent"
      camera={{ near: 0.01, far: 100 }}
      gl={{
        powerPreference: "high-performance",
        preserveDrawingBuffer: true,
        // alpha: true,
        antialias: true,
        // outputEncoding: THREE.sRGBEncoding,
        physicallyCorrectLights: true,
      }}
      onCreated={({ camera }) => {
        camera.position.x = 0;
        camera.position.y = isMobile ? 0.05 : 0.075;
        camera.position.z = isMobile ? 0.65 : 0.55;
        camera.lookAt(lookAtPos);
        camera.updateProjectionMatrix();
        // camera.fov =
      }}
    >
      {/* <OrbitControls
          target={[0, 0.1, 0]}
          enablePan={false}
          enableDamping={true}
          minAzimuthAngle={-Math.PI * 0.1}
          maxAzimuthAngle={Math.PI * 0.1}
          maxPolarAngle={Math.PI * 0.5}
          minPolarAngle={Math.PI / 2.33}
          maxDistance={0.5}
          minDistance={0.25}
        /> */}

      <Suspense fallback={null}>
        <World />
        <Environment
          files={`/envMaps/empty_warehouse_01_1k.hdr`}

          // preset={envMapState}
        />
        {/* <Fireflies /> */}
        <LoadingCloudModel />
      </Suspense>
      <fog attach="fog" args={[new THREE.Color(0xffffff), 1, 7]} />
      <Suspense fallback={null}>
        <PresentationControls global polar={[0, 0]} speed={3}>
          {friendsieState && (
            <FriendiseModel
              friendsieState={friendsieState}
              mockObject={mockObject}
              downloadingModel={downloadingModel}
              setEntireScene={setEntireScene}
              // setHeadAnimation={setHeadAnimation}
              // setSproutAnimation={setSproutAnimation}
              // setBodyAnimation={setBodyAnimation}
              // setAccessoryAnimation={setAccessoryAnimation}
              // setShoeAnimation={setShoeAnimation}
              // setBackpieceAnimation={setBackpieceAnimation}
              // setRendererInfo={setRendererInfo}
              ref={ref}
            />
          )}
        </PresentationControls>
      </Suspense>
    </Canvas>
  );
};

export default memo(forwardRef(CanvasComponent));
