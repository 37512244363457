import React, { useRef, useEffect } from "react";
import "./Landing.css";
import ReactFullpage from "@fullpage/react-fullpage";
import logo from "../../assets/images/dev/logo3.png";
import fwyLogo from "../../assets/images/dev/fwy-logo.png";
import newLogo from "../../assets/images/dev/logo-new.png";

import cactus from "../../assets/images/dev/cactus-no-boy.png";
import rainbowCloud from "../../assets/images/dev/rainbow-cloud2.png";
import cloudboyWand from "../../assets/images/dev/cloudboy-wand.png";
import greenChunk from "../../assets/images/dev/green-chunk.png";
import rainbow from "../../assets/images/dev/rainbow.png";
import enterFrengen from "../../assets/images/dev/enter-frengenv2.png";
import fwybg from "../../assets/images/dev/bg-2.png";
import hiCloud from "../../assets/images/HiCloud.png";
import frengen from "../../assets/images/dev/frengen.png";
import friendsiesVideo from "../../assets/images/dev/fullscreen.mp4";

import heartOne from "../../assets/images/heartOne.png";
import heartTwo from "../../assets/images/heartTwo.png";
import heartThree from "../../assets/images/heartThree.png";
import heartFour from "../../assets/images/heartFour.png";
import heartFive from "../../assets/images/heartFive.png";
import sgck from "../../assets/images/sgck.png";

import cloudboyFloat from "../../assets/images/dev/compress-float.gif";
import friendsiesStand from "../../assets/images/dev/compress-stand.gif";
import friendsiesWalk from "../../assets/images/dev/compress-walk.gif";

import EnterQueue from "../EnterQueue";
import Header from "../Header";
import { Link } from "react-router-dom";

const DevLanding = ({ signer, connectHandler, disconnectHandler }) => {
  return (
    <div>
      {/* <EnterQueue signer={signer} /> */}
      <Header
        signer={signer}
        connectHandler={connectHandler}
        disconnectHandler={disconnectHandler}
      />
      <img
        src={newLogo}
        alt="lg-one"
        className="mx-auto xl:w-1/2 md:w-3/4 sm:w-full -mb-12 xl:-mb-16"
      />
      {/* <div className="relative">
        <h1 className="text-2xl xl:text-3xl mx-auto text-center text-white h-font">
          Claim Now
        </h1>
        <img
          src={sgck}
          alt="super golden cloud key"
          className="absolute mx-auto left-60 right-0 -top-1 w-14 inline"
        />
      </div> */}
      {/* <h3 className="text-md xl:text-l mx-auto text-center text-white h-font">Snapshot completed on 04/02!</h3> */}
      <a href="/builder">
        <img
          src={enterFrengen}
          alt="enter-frengen"
          className="mx-auto mt-8 block xl:w-1/4 md:w-1/2 w-3/4 -mb-8"
        />
      </a>

      {/* Part one */}
      <div className="grid lg:grid-cols-2 mt-16">
        <div className="lg:order-first mx-8 xl:mx-0 mx-auto order-last">
          <div className="relative xl:left-1/3 xl:w-3/4">
            {/* todo: merge */}
            <a href="/builder" className="hidden">
              <img
                src={frengen}
                alt="cactus"
                className="w-1/2 absolute top-20"
              />
            </a>
            <img
              src={cloudboyFloat}
              alt="cloudbody-float"
              className="w-2/12 absolute cloudboy-position"
            />
            <img
              src={friendsiesStand}
              alt="friendsies-stand"
              className="absolute stand-position"
            />
            <img
              src={friendsiesWalk}
              alt="friendsies-walk"
              className="absolute walk-position"
            />
            <img src={cactus} alt="cactus" />
          </div>
        </div>
        <div className="xl:px-24 px-12 mt-16">
          <img src={hiCloud} alt="hi-cloud" className="floating h-28 mb-4" />
          <div>
            <h1 className="text-4xl text-white mb-2 h-font">
              WELCOME TO THE GARDEN!
            </h1>
            <p className="text-white p-font text-justify">
              fRiENDSiES are your forever friend. Your new digital companion and
              inclusion into the kindness community aka the garden. This
              collection of 10,000 NFTs are made and designed with love by
              FriendsWithYou. Choose from hundreds of character combinations
              including custom heads, bodies, sprouts, hand held items, tails,
              and more! These traits contain dozens of special 1/1s and
              extremely rare attributes to collect. Each fRiENDSiES is custom
              built during the mint by the collector with our one-of-a-kind NFT
              builder the FRENGEN. The entire collection will be built & curated
              simultaneously by our community.
            </p>
            <p className="text-white p-font mt-4 text-justify">
              The fRiENDSiES universe begins by generating your fRiENDSiES and
              will extend into the metaverse, real life experiences & art
              installations, limited edition items and ultimately creating an AR
              AI companion that will help bring joy to its holder and their
              community.
            </p>
          </div>
        </div>
      </div>

      {/* Part two */}
      <div className="relative mt-32">
        <div className="relative mb-8 mx-12 lg:mx-32">
          <img
            src={cloudboyWand}
            alt="cloud-boy-wand"
            className="-z-10 w-5/12 xl:w-1/6"
          />
        </div>

        {/* OLD CODE */}
        {/*<div className="relative mb-8 w-3/12 xl:w-1/6 lg:mx-32 mx-12">
					<img src={cloudboySit} alt="cloud-boy-sit" className="z-10"/>
					<img src={cloudboyWand} alt="cloud-boy-wand" className="absolute cloudboy-sit-position"/>
				</div>*/}

        <div className="pr-12 pl-20 mx-auto md:mx-0 md:w-3/4 lg:w-7/12 xl:pl-48 w-full pb-32 lg:pb-0">
          <h1 className="text-4xl text-white h-font">
            The road to 10,000 fRiENDSiES
          </h1>
          <ul className="mt-8">
            <li className="text-white mt-8 p-font text-justify">
              <img
                src={heartOne}
                alt="heart"
                className="absolute h-10 -ml-16 -mt-1"
              />
              <h2 className="text-xl h-font">Christie’s Auction on OpenSea</h2>
              <h3 className="p-font text-sm">
                Mar. 26th, 2022 at 12 p.m. ET to Mar. 28th, 2022 at 12 p.m. ET
              </h3>
              <p className="text-white p-font mt-4 text-justify">
                In this historical sale, FriendsWithYou will be auctioning off 9
                mint passes granting exclusive early access to the fRiENDSiES
                NFT character builder. Each lot will provide collectors, in
                sequential order, access to the NFT builder before it opens to
                the public. Design assets in the engine are available with
                varying rarities. The sooner you enter the builder, the greater
                the chance you can build the fRiENDSiE of your dreams. Prior to
                the public sale, 124 ghost clouds and friends will gain early
                access to pre-mint their fRiENDSiES NFT, on Apr. 2nd at 12 a.m.
                ET.
              </p>
            </li>
            <li className="text-white mt-8 p-font text-justify">
              <img
                src={heartTwo}
                alt="heart"
                className="absolute h-10 -ml-16 -mt-1"
              />
              <h2 className="text-xl h-font">Public Dutch Auction Sale</h2>
              <h3 className="p-font text-sm">
                Apr. 2nd, 2022 at 12 p.m. ET until SOLD OUT
              </h3>
              <p className="text-white p-font mt-4 text-justify">
                In the public sale, 8765 fRiENDSiES will be sold through a dutch
                auction. Every collector of a fRiENDSiES NFT will custom build
                their NFT. This process is different from other mints so read
                carefully! The asking price will start at 3.33 ETH. Buyers can
                purchase up to 5 items per transaction. Every 15 minutes the
                price will decrease by 0.1 ETH before reaching a final reserve
                price of 0.1 ETH. After purchase, you will immediately enter the
                builder to customize your NFT. If you're seeking rare items,
                please work quickly as other collectors will be building at the
                same time. Rare items will disappear as they're consumed.
              </p>
            </li>
            <li className="text-white mt-8 p-font text-justify">
              <img
                src={heartThree}
                alt="heart"
                className="absolute h-10 -ml-16 -mt-1"
              />
              <h2 className="text-xl md:text-l h-font">
                Super Golden Cloud Key Mint
              </h2>
              <h3 className="sm:block p-font text-sm">
                Apr. 3rd, 2022 at 12 p.m. ET until SOLD OUT
              </h3>
              <p className="text-white p-font mt-4 text-justify">
                The 1111 Super Golden Cloud Key holders will be able to create a
                fully customized 3D fRiENDSiE for no additional charge. Claiming
                your fRiENDSiE will begin on Sunday, April 3rd at 12 p.m. ET.
                Super Golden Cloud key members will claim on a
                first-come-first-serve basis.
              </p>
            </li>
            <li className="text-white mt-8 p-font text-justify">
              <img
                src={heartFour}
                alt="heart"
                className="absolute h-10 -ml-16 -mt-1"
              />
              <h2 className="text-xl md:text-l h-font">Public Reveal</h2>
              <h3 className="sm:block p-font text-sm">at SOLD OUT</h3>
              <p className="text-white p-font mt-4 text-justify">
                Our technology enables us to reveal the individual NFTs
                immediately after the mint completes. As soon as you complete
                construction of your fRiENDSiES, our system will transform it
                into an on-chain asset and unchangeable item of the collection.
                You will receive assets to share on Twitter and update your pfp.
                Shortly after selling out and the entire collection has
                completed we will reveal all fRiENDSiES simultaeously on
                OpenSea.
              </p>
            </li>
            <li className="text-white mt-8 p-font text-justify">
              <img
                src={heartFive}
                alt="heart"
                className="absolute h-10 -ml-16 -mt-1"
              />
              <h2 className="text-xl md:text-l h-font">fRiENDSiES Forever</h2>
              <h3 className="sm:block p-font text-sm">
                The rest of 2022 and beyond
              </h3>
            </li>
          </ul>
        </div>
        <img
          src={greenChunk}
          alt="green-chunk"
          className="hidden -z-10 lg:block"
        />
        <img
          src={rainbowCloud}
          alt="rainbow-cloud"
          className="hidden -z-10 absolute lg:w-full xl:w-3/4 bottom-0 right-0 md:block"
        />
      </div>

      {/* Part three */}
      <video
        id="friendsies-vid"
        className="w-full"
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={friendsiesVideo} type="video/mp4" />
      </video>

      {/* Part four */}
      <div className="grid fwy-background w-full h-screen content-center">
        <div className="mx-auto xl:px-96 md:px-24 px-12">
          <h2 className="text-xl text-white mb-8 h-font text-center">
            Created by...
          </h2>
          <img
            src={fwyLogo}
            alt="lg-one"
            className="mx-auto xl:w-1/4 w-1/2 mb-8"
          />
          <p className="text-white p-font text-justify mb-6">
            FriendsWithYou has cultivated a community of positivity for over 20
            years working together. Their mission statement is to spread joy,
            kindness, empathy and love throughout the world. They have achieved
            this through a variety of mediums: painting, sculpture, fine art
            editions, traditional media (television, animation) and most notably
            through large-scale immersive public experiences. They have created
            the hit animation series
            <a
              className="underline mx-1"
              href="https://www.netflix.com/title/80063599"
            >
              True and the Rainbow Kingdom
            </a>
            on Netflix, participated in the
            <a
              className="underline mx-1"
              href="https://twitter.com/Friendswithyou/status/1463937201394184193?s=20"
            >
              Macy's Thanksgiving Day Parade
            </a>
            in 2018 featuring their iconic Little Cloud character, and have
            exhibited their artworks in museums and public venues worldwide.
          </p>

          <p className="text-center mx-auto text-white p-font mb-4">
            Visit{" "}
            <a className="underline mx-1" href="https://friendswithyou.com">
              friendswithyou.com
            </a>{" "}
            to learn more.
          </p>

          <p className="text-center text-white p-font mb-4">
            Sam & Tury
            <br />{" "}
            <a
              className="underline mx-1"
              href="https://twitter.com/Friendswithyou"
            >
              @FriendsWithYou
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default DevLanding;
