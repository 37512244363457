import React, { memo, useRef } from 'react';
import { useGLTF, useAnimations, useProgress } from '@react-three/drei';

const LoadingCloudModel = (props) => {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF('/models/loadingCloud.glb');
  const { active } = useProgress();

  return (
    // visible={active ? false : true}
    <group ref={group} {...props} dispose={null} position={[0, -0.2, 0]} visible={active ? true : false}>
      <group name="Scene">
        <group name="Character_Rig">
          {/* <primitive object={nodes.Root} /> */}
          <mesh
            name="LittleCloud"
            geometry={nodes.LittleCloud.geometry}
            material={materials['M_LittleCloud.001']}
            // skeleton={nodes.LittleCloud.skeleton}
          />
        </group>
      </group>
    </group>
  );
};

export default memo(LoadingCloudModel);

useGLTF.preload('/models/loadingCloud.glb');
