import "./index.css";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./index.css";
import App from "./App.js";
import reportWebVitals from "./reportWebVitals";
import Main from "./pages/main.js";
import Mint from "./pages/mint.js";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from "ethers";
import Builder from "./pages/builder";
import Landing from "./pages/landing";
import { Provider, useDispatch } from "react-redux";
import { store } from "./state/store";
import Roadmap from "./pages/roadmap";
import Share from "./pages/share";
import OpenSea from "./pages/opensea";
import Vectary from "./pages/vectary";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import {
  setUserAuthToken,
  setBuilderState,
  getUserSignature,
  setUserSignature,
  setSharePageLinks,
} from "./state/user/userSlice";

// import GSAPScroll from "./components/GSAPScroll";
// import axios from 'axios';

let provider;
let callbacks = [];
let disconnectHandler;

const providerOptions = {
  walletlink: {
    package: CoinbaseWalletSDK,
    options: {
      appName: "friendsies",
      infuraId: process.env.INFURA_KEY,
      rpc: `https://${process.env.REACT_APP_NETWORK}.infura.io/v3/${process.env.INFURA_KEY}`,
    },
  },
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: process.env.REACT_APP_INFURA_API_KEY,
    },
  },
};

const web3Modal = new Web3Modal({
  network: process.env.REACT_APP_NETWORK,
  cacheProvider: true,
  providerOptions,
});

const web3Signer = () => {
  if (!provider) return null;

  // TODO As long as provider's chainId doesn't change, we should
  // be able to cache the signer instead of creating a new instance.
  return new ethers.providers.Web3Provider(provider).getSigner();
};

const web3SignerOnChange = (cb) => {
  callbacks.push(cb);
};

const setDisconnectHandler = (h) => {
  disconnectHandler = h;
};

const setProvider = (p) => {
  if (provider) throw new Error("web3Signer: provider already set");
  provider = p;

  // bind to events triggered by user's wallet
  provider.on("accountsChanged", accountsChangedEventHandler);
  provider.on("chainChanged", callCallbacks);
  provider.on("connect", callCallbacks);
  provider.on("disconnect", () => {
    if (disconnectHandler) {
      disconnectHandler();
    }
    callCallbacks();
  });

  callCallbacks();
};

const clearProvider = (p) => {
  web3Modal.clearCachedProvider();
  web3Modal.off("accountsChanged");
  web3Modal.off("chainChanged");
  web3Modal.off("connect");
  web3Modal.off("disconnect");
  provider = null;
};

const callCallbacks = () => {
  try {
    const s = web3Signer();
    callbacks.forEach((cb) => {
      cb(s);
    });
  } catch (error) {
    console.log("Error calling callbacks");
    console.log(error);
  }
};

const accountsChangedEventHandler = async () => {
  if (!(await signerHasAddress())) {
    if (disconnectHandler) {
      disconnectHandler();
    }
  }

  callCallbacks();
};

const signerHasAddress = async () => {
  try {
    const s = web3Signer();
    if (!s) return false;

    try {
      if (!(await s.getAddress())) return false;
    } catch {
      return false;
    }
  } catch (error) {
    console.log("Error retrieving signer");
    console.log(error);
  }

  return true;
};

function Root() {
  const [signer, setSigner] = useState(null);

  const dispatch = useDispatch();

  const connect = async () => {
    try {
      const p = await web3Modal.connect();
      let signer = new ethers.providers.Web3Provider(p).getSigner();

      setSigner(signer);
      setProvider(p);

      // get user signature: if they dont sign, disconnect
      dispatch(getUserSignature(signer, disconnect));
    } catch (error) {
      console.log(error);
    }
  };

  const disconnect = async () => {
    try {
      web3Modal.clearCachedProvider();
      setSigner(null);
      clearProvider();

      try {
        store.dispatch(setUserAuthToken(""));
        store.dispatch(setBuilderState("preview"));
        store.dispatch(setSharePageLinks(null));

        if (signer) {
          const userAddress = await signer.getAddress();
          localStorage.removeItem(userAddress);
        }
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   lazyConnectLastKnownSigner();
  // });

  useEffect(() => {
    web3SignerOnChange(async (signer) => {
      setSigner(signer);
    });
    setDisconnectHandler(disconnect);
  });

  const isInjectedProviderUnlocked = async () => {
    if (!window.ethereum) return false;
    const p = new ethers.providers.Web3Provider(window.ethereum);
    const accounts = await p.listAccounts();
    return accounts && accounts.length > 0;
  };

  const lazyConnectLastKnownSigner = async () => {
    if (signer) return;
    if (!web3Modal.cachedProvider) return;
    if (web3Modal.cachedProvider === "injected") {
      if (await isInjectedProviderUnlocked()) {
        connect();
      }
    }
  };

  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<App signer={signer} connectHandler={connect} disconnectHandler={disconnect} />}>
          <Route index element={<Main />} />
        </Route> */}
        <Route path="/">
          <Route
            index
            element={
              <Landing
                signer={signer}
                connectHandler={connect}
                disconnectHandler={disconnect}
              />
            }
          />
        </Route>

        {/* <Route path="/preview">
          <Route index element={<Builder signer={signer} connectHandler={connect} disconnectHandler={disconnect} builder={false} />} />
        </Route> */}

        <Route path="/builder">
          <Route
            index
            element={
              <Builder
                signer={signer}
                connectHandler={connect}
                disconnectHandler={disconnect}
              />
            }
          />
        </Route>

        <Route path="/share/:seedId">
          <Route
            index
            element={
              <Share
                signer={signer}
                connectHandler={connect}
                disconnectHandler={disconnect}
              />
            }
          />
        </Route>

        <Route path="/opensea/scene/:seedId">
          <Route index element={<OpenSea />} />
        </Route>

        <Route path="/ar/:link">
          <Route index element={<Vectary />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Root />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
