import React, { memo, useEffect, useState } from 'react';
import { getActiveUsers } from '../../api/api';
import { setIntervalImmediately } from '../MintWidget';
import classes from '../MintWidget/MintWidget.module.css';

const Stats = () => {
  const [activeUsers, setActiveUsers] = useState(1);
  // poll state every 10 seconds
  useEffect(() => {
    const interval = setIntervalImmediately(async () => {
      const currentDutchAuctionState = await getActiveUsers();
      setActiveUsers(currentDutchAuctionState);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`${classes.mintWidgetContainer} md:p-1 lg:p-1 mt-4 rounded-xl`}>
      <div className={` place-content-center items-center flex  font-FredokaOne`}>
        <p className="text-center text-fbuilderGreen font-FredokaOne text-xs p-2">{`${activeUsers} ${
          activeUsers > 1 || activeUsers === 0 ? 'friends are currently building' : 'friend is currently building'
        }`}</p>
      </div>
    </div>
  );
};

export default Stats;
