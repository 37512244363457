import { useTexture } from '@react-three/drei';
import { useFrame, useLoader, useThree } from '@react-three/fiber';
import { memo, useEffect, useRef } from 'react';
import * as THREE from 'three';
import { CubeTextureLoader } from 'three';

// const skyboxTextures = Array(6).fill('/textures/skyGradients/cubeSky.png');

const SkyDome = () => {
  const skyDomeRef = useRef();
  const { scene } = useThree();
  let testTexture = useTexture('/textures/skyGradients/skyGradientTen.png');
  // const loader = new CubeTextureLoader();

  // const cubeMapTexture = loader.load(skyboxTextures);

  testTexture.flipY = true;
  testTexture.wrapS = testTexture.wrapT = THREE.RepeatWrapping;

  useFrame(({ clock }) => {
    //   skyDomeRef.current.rotation.x = clock.getElapsedTime() / 50;
    testTexture.offset.set(clock.getElapsedTime() / 500, 0);
  });

  // useEffect(() => {
  //   testTexture.wrapS = testTexture.wrapT = THREE.RepeatWrapping;
  //   testTexture.encoding = THREE.sRGBEncoding;
  // }, [testTexture]);

  // const data = useMemo(
  //   () => ({
  //     uniforms: skyShaderMaterial.uniforms,
  //     fragmentShader: skyShaderMaterial.fragmentShader,
  //     vertexShader: skyShaderMaterial.vertexShader,
  //   }),
  //   []
  // );

  return (
    <>
      <mesh
        ref={skyDomeRef}
        position={[0, 18, -50]}
        dispose={null}

        // sky dome position
        // position={[0, -100, 0]}
      >
        {/* <sphereBufferGeometry args={[800, 16, 32, 0, Math.PI * 2.0, 0, Math.PI / 1.875]} attach="geometry" /> */}
        <planeBufferGeometry args={[250, 75]} attach="geometry" />
        <meshBasicMaterial map={testTexture} side={THREE.FrontSide} attach="material" fog={false} />
        {/* <shaderMaterial attach="material" {...data} /> */}
      </mesh>
    </>
  );
};

export default memo(SkyDome);
