import { useState, useEffect, useCallback } from 'react'
import classes from './mint.module.css';
import axios from "axios";
import VerifyModal from "../components/VerifyModal"
import ConfirmModal from "../components/ConfirmModal"
import cloudVideo from '../assets/SuperGoldenCloudKey.mp4';
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { ethers } from 'ethers'
import { Friendsies1155Contract } from '../contracts/Friendsies1155.js'

const web3Provider = new ethers.providers.AlchemyProvider(process.env.REACT_APP_NETWORK, process.env.REACT_APP_ALCHEMY_API_KEY)

export const setIntervalImmediately = (fn, interval) => {
  fn()
  return setInterval(fn, interval)
}

function Mint(props) {
  const {
    signer,
    connectHandler
  } = props

  const [canClaim, setCanClaim] = useState(false);
  const [checkInbox, setCheckInbox] = useState(false);
  const [isVerifyOpen, setVerifyOpen] = useState(false)
  const [isConfirmOpen, setConfirmOpen] = useState(false)
  const [hasToken, setHasToken] = useState(false);
  const [hasMinted, setHasMinted] = useState(false);
  const [hasWalletAllow, setHasWalletAllow] = useState(false);
  const [niftyCallbackFailed, setNiftyCallbackFailed] = useState(false)

  let navigate = useNavigate()
  const [cookies, setCookie] = useCookies(['token']);

  const openVerifyModal = () => {
    setVerifyOpen(true)
  }

  const openConfirmModal = () => {
    setConfirmOpen(true)
  }

  const verifyAddress = useCallback(async () => {
    console.log("checking allow list")
    if (!signer) {
      return;
    }

    try {
      const response = await axios.post(process.env.REACT_APP_SERVICE_URL + "/verifyAddress", {
        address: await signer.getAddress(),
      });

      if (!response.data || (response.data && response.data?.allowed === false)) {
        console.log("address not whitelisted")
        return
      }

      setCanClaim(true)
      setHasToken(true)
      setHasWalletAllow(true)
      const oneHour = 60 * 60 * 1000
      var inOneHour = new Date();
      inOneHour.setTime(inOneHour.getTime() + oneHour)
      setCookie('token', response.data, { path: '/', secure: true, expires: inOneHour });

    } catch (error) {
      console.log(error);
    }
  }, [signer, setCookie])

  useEffect(() => {
    verifyAddress()
  }, [signer, verifyAddress])


  // Handle URL or cookie based tokens
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const urlToken = query.get("token")
    const t = urlToken || cookies.token
    if (!t || t === "undefined") {
      setHasToken(false)
      return
    }

    if (urlToken) {
      const oneHour = 60 * 60 * 1000
      var inOneHour = new Date();
      inOneHour.setTime(inOneHour.getTime() + oneHour)
      setCookie('token', t, { path: '/', secure: true, expires: inOneHour });
      navigate("/mint", { replace: true })
    }

    setHasToken(true)
  }, [setHasToken, setCookie, navigate, cookies.token])

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const allowed = query.get("allowed")
    if (allowed === "false") {
      setNiftyCallbackFailed(true)
    }
  }, [setNiftyCallbackFailed])

  const [mintableSupply, setMintableSupply] = useState(null);
  const [maxSupply, setMaxSupply] = useState(null);

  useEffect(() => {
    const interval = setIntervalImmediately(async () => {
      const y = (await Friendsies1155Contract(web3Provider).maxSupply(1)).toNumber()
      setMaxSupply(y)

      const x = (await Friendsies1155Contract(web3Provider).mintableSupply(1)).toNumber()
      setMintableSupply(x)
    }, 30000);
    return () => clearInterval(interval);
  }, []);


  const mintedSuccessfully = () => {
    setHasMinted(true)
  }

  return (
    <div className="container mx-auto px-8 lg:px-40 py-12 lg:py-32">
      <VerifyModal isOpen={isVerifyOpen} setIsOpen={setVerifyOpen} checkInbox={checkInbox} setCheckInbox={setCheckInbox} />
      <ConfirmModal isOpen={isConfirmOpen} setIsOpen={setConfirmOpen} signer={signer} success={mintedSuccessfully} />

      <div className="grid xl:grid-cols-2">
        <div>
          { /* Painfully position on the page. */}
          <div className="grid grid-cols-1 lg:max-h-128 max-h-96">
            <video className="justify-self-center relative lg:-top-32 md:-top-16 -top-8 w-full lg:max-w-lg max-w-md" id="cloud-vid" width="320" height="240" autoPlay loop muted playsInline>
              <source src={cloudVideo} type="video/mp4" />
            </video>

            <div className="justify-self-center z-1 relative lg:-top-52 md:-top-28 -top-16 text-gray-800 lg:w-2/3">
              <p className="text-center">
                The Super Golden Cloud Key is the key to the friendsies ecosystem. This distribution is limited to 1111
                of these special keys. They are available exclusively to members of the FriendsWithYou
                community.
                Any key owner will have exclusive access to the community and to one mint of the Friendsies NFT, free of charge.
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:text-xl text-xl mt-32 lg:mt-24 lg:ml-12 bg-gray-100 rounded-xl px-8 py-8">
          <h1 className="text-lg lg:text-2xl font-semibold text-gray-500 mt-4">Mint Super Golden Cloud Key</h1>
          <h2 className="w-full mt-6 h-auto header-font">
            <div className={classes.status}></div>
            {hasMinted ? <span className="mt-8">Congrats! You successfully minted a Super Golden Cloud Key!</span> : <span>
              {niftyCallbackFailed && <span className="mt-8">
                NiftyGateway verification failed.
                You must own a "Cloud10", "Cloud10 Open Edition", or "Cloud10 Charity Drop" token by Diplo x FriendsWithYou in order to be eligible.</span>}
              {!signer && <span className="mt-8">Please connect your wallet</span>}
              {signer && canClaim && hasWalletAllow && <span className="mt-8">Your wallet is on the allow list. Mint available.</span>}
              {signer && hasToken && !hasWalletAllow && <span className="mt-8">Your email is verified. Mint available.</span>}
              {signer && !canClaim && !hasToken && !niftyCallbackFailed && <span className="mt-8 text-sm md:text-xl">Your wallet is not on the allow list! Please verify your email to continue.</span>}
            </span>}
          </h2>

          {!hasMinted &&
            <div className="grid">
              {!signer && <button className="w-3/4 rounded-lg bg-gray-800 text-white mx-auto mt-8 py-2 lg:text-2xl font-bold hover:bg-gray-700" onClick={connectHandler}>Connect Wallet</button>}
              {signer && !canClaim && !hasToken && <button className="w-3/4 rounded-lg bg-gray-800 text-white mx-auto mt-8 py-2 lg:text-2xl font-bold hover:bg-gray-700" onClick={openVerifyModal}>Verify Email</button>}
              {signer && (canClaim || hasToken) && <button className="w-3/4 rounded-lg bg-gray-800 text-white mx-auto mt-8 py-2 lg:text-2xl font-bold hover:bg-gray-700" onClick={openConfirmModal}>Mint!</button>}
            </div>
          }

          {!hasMinted &&
            <div className="text-center mt-4">
              {mintableSupply !== null && <h2 className="text-xl">{maxSupply - mintableSupply} out of {maxSupply} minted</h2>}
            </div>
          }
          <div></div>
        </div>
      </div>
    </div>
  )
}

export default Mint;
