import { Leva } from 'leva';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const DebugTools = ({ friendsieState, setEnvMapState, envMapState, animationDuration }) => {
  const dispatch = useDispatch();
  const [lightControlsToggle, setLightControlsToggle] = useState(false);
  const authTokenId = useSelector((state) => state.user.authTokenId);
  return (
    <div className="hidden lg:block">
      {/* DEVELOPMENT AND TESTING -- IGNORE */}

      {/* {builderState === 'building' && (
                  <div className="flex flex-row items-center place-content-center">{ws.current ? 'socket connected' : 'socket not connected'}</div>
                )} */}

      {/* <button onClick={() => setLightControlsToggle(!lightControlsToggle)} className="bg-fyellowtraitborder mt-1 rounded-full px-3 py-1">
        {`${lightControlsToggle ? 'hide' : 'show'} light controls`}
      </button> */}

      {/* https://market-assets.fra1.cdn.digitaloceanspaces.com/market-assets/hdris/forest_slope_1k.hdr */}
      {/* {lightControlsToggle && (
        <select value={envMapState} onChange={(e) => setEnvMapState(e.target.value)}>
          <option value="empty_warehouse_01_1k.hdr">warehouse</option>
          <option value="lilienstein_1k.hdr">lilienstein</option>
          <option value="venice_sunset_1k.hdr">sunset</option>
          <option value="potsdamer_platz_1k.hdr">city</option>
          <option value="rooitou_park_1k.hdr">park</option>
          <option value="forest_slope_1k.hdr">forest</option>
          <option value="immenstadter_horn_1k.hdr">field</option>
          <option value="kiara_1_dawn_1k.hdr">dawn</option>
          <option value="rustig_koppie_1k.hdr">rustig</option>
          <option value="pool_1k.hdr">pool</option>
          <option value="kiara_8_sunset_1k.hdr">kiara</option>
          <option value="autumn_park_1k.hdr">autumn</option>
          <option value="studio008.hdr">"D-man" studio 🤪</option>
        </select>
      )} */}
      {/* {ws.current && (
                  <button className="bg-black text-white" onClick={() => ws.current.close()}>
                    close socket
                  </button>
                )} */}

      {/* <select value={authTokenId} onChange={(e) => dispatch(setAuthTokenId(e.target.value))}>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
      </select> */}

      {/* <button onClick={() => toast('Toast test!')}>Toast test</button> */}
      {/* <p>Auth token: {authToken ? authToken : 'none'}</p> */}
      {/* <EnterQueue signer={signer} /> */}
      {/* <p>{rendererInfo ? `Geometries: ${rendererInfo.memory.geometries}` : null}</p>
                <p>{rendererInfo ? `Textures: ${rendererInfo.memory.textures}` : null}</p>
                <p>{rendererInfo ? `triangles: ${rendererInfo.render.triangles}` : null}</p>
                <p>{rendererInfo ? `calls: ${rendererInfo.render.calls}` : null}</p> */}
      {/* <br /> */}

      {/* <p>{`Accessory: ${friendsieState.accessory.name ? friendsieState.accessory.name : 'none'}`}</p>
      <p>{`Backpiece: ${friendsieState.backpiece.name ? friendsieState.backpiece.name : 'none'}`}</p>
      <p>{`Sprout: ${friendsieState.sprout.name && !friendsieState.headNoSprout ? `${friendsieState.sprout.name} ` : 'none'}`}</p>
      <p>{`Body: ${friendsieState.body.name}`}</p>
      <p>{`Shoes: ${friendsieState.shoe.name ? friendsieState.shoe.name : 'none'}`}</p>
      <p>{`Head: ${friendsieState.head.name}`}</p>
      <p>{`Face: ${friendsieState.face ? friendsieState.face.name : 'none'}`}</p>
      <p>{`Body animation duration: ${friendsieState.fixedModel ? 'none' : animationDuration}`} </p> */}

      {/* <p>{`Sprout: ${friendsieState.sprout.name ? `${friendsieState.sprout.name} // size: ${friendsieState.sprout.part}` : friendsieState.sprout}`}</p> */}
      {/* <p>{`Head Available?: ${friendsieState.head.available ? friendsieState.head.available : 'null value'}`}</p> */}
      {/* <p>{`Face: ${friendsieState.face && friendsieState.face.name} // color: ${friendsieState.face && friendsieState.face.part}`}</p> */}

      {/* <p>{`headColor: ${friendsieState.headColor && friendsieState.headColor}`}</p>
      <p>{`headFixed: ${friendsieState.headFixed && friendsieState.headFixed}`}</p>
      <p>{`headSize: ${friendsieState.headSize && friendsieState.headSize}`}</p>
      <p>{`headNoSpout: ${friendsieState.headNoSprout && friendsieState.headNoSprout}`}</p>
      <p>{`fixedModel: ${friendsieState.fixedModel && friendsieState.fixedModel}`}</p> */}

      {/* Panel 0: FPS Frames rendered in the last second. The higher the number the better. */}
      {/* Panel 1: MS Milliseconds needed to render a frame. The lower the number the better.*/}
      {/* Panel 2: MB MBytes of allocated memory. (Run Chrome with --enable-precise-memory-info) */}
      {/* <Stats showPanel={0} className="mt-0" />
            <Stats showPanel={1} className="mt-10" />
            <Stats showPanel={2} className="mt-20" /> */}

      {/* <Leva hidden={lightControlsToggle ? false : true} /> */}
    </div>
  );
};

export default DebugTools;
