import React, { memo, useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';

const GreenHill = (props) => {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF('/models/world/GreenHill.glb');

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        {/* <group name="Character_Rig">
          <group name="Root" rotation={[0.02, 0, 0]}>
            <group name="Spine1" position={[0, 0.07, 0]} rotation={[-0.04, 0, 0]}>
              <group name="Spine2" position={[0, 0.02, 0]}>
                <group name="Spine3" position={[0, 0.03, 0]} rotation={[-0.03, 0, 0]}>
                  <group name="Head" position={[0, 0.04, 0]} rotation={[0.02, 0.02, 0]} />
                  <group name="BiscepL" position={[0.04, 0.02, 0]} rotation={[-0.54, 0.02, -2.44]}>
                    <group name="ArmL" position={[0, 0.02, 0]} rotation={[0.23, 0.1, -0.56]}>
                      <group name="HandL" position={[0, 0.03, 0]} rotation={[-0.05, 0.01, -0.16]}>
                        <group name="AttachmentL" position={[0, 0.01, 0]} rotation={[0, 0, -0.01]} />
                      </group>
                    </group>
                  </group>
                  <group name="BiscepR" position={[-0.04, 0.02, 0]} rotation={[-0.49, 0, 2.44]}>
                    <group name="ArmR" position={[0, 0.02, 0]} rotation={[0.19, 0.09, 0.45]}>
                      <group name="HandR" position={[0, 0.03, 0]} rotation={[-0.09, 0.21, 0.17]}>
                        <group name="AttachmentR" position={[0, 0.01, 0]} rotation={[0, 0.04, 0.06]} />
                      </group>
                    </group>
                  </group>
                </group>
                <group name="Backpiece_Attachment" position={[0, 0.01, -0.02]} rotation={[-1.56, 0, 0]} />
              </group>
            </group>
            <group name="ThighL" position={[0.02, 0.06, 0]} rotation={[-1.55, 1.27, -1.85]}>
              <group name="ShinL" position={[0, 0.02, 0]} rotation={[-0.07, 0.06, 0.61]}>
                <group name="ToeL" position={[0, 0.02, 0]} rotation={[-0.05, -0.12, -1.07]} />
              </group>
            </group>
            <group name="ThighR" position={[-0.02, 0.06, 0]} rotation={[-2.21, -1.28, 1.2]}>
              <group name="ShinR" position={[0, 0.02, 0]} rotation={[-0.05, -0.03, -0.44]}>
                <group name="ToeR" position={[0, 0.02, 0]} rotation={[-0.03, 0.1, 0.87]} />
              </group>
            </group>
          </group>
        </group> */}
        <mesh
          name="Daisy001"
          castShadow
          receiveShadow
          geometry={nodes.Daisy001.geometry}
          material={nodes.Daisy001.material}
          position={[0.19, -0.027, 0.2]}
          rotation={[-1.56, 0, -0.56]}
          scale={[1.08, 1.08, 1.08]}
        />
        <mesh
          name="Daisy002"
          castShadow
          receiveShadow
          geometry={nodes.Daisy002.geometry}
          material={nodes.Daisy002.material}
          position={[0.23, -0.027, 0.17]}
          rotation={[-1.55, 0.03, -0.54]}
          scale={1.08}
        />
        <mesh
          name="Daisy003"
          castShadow
          receiveShadow
          geometry={nodes.Daisy003.geometry}
          material={nodes.Daisy003.material}
          position={[0.23, -0.027, 0.23]}
          rotation={[-1.54, 0, -0.55]}
          scale={[1.08, 1.08, 1.08]}
        />
        <mesh
          name="Daisy004"
          castShadow
          receiveShadow
          geometry={nodes.Daisy004.geometry}
          material={nodes.Daisy004.material}
          position={[-0.14, -0.026, 0.24]}
          rotation={[-1.55, -0.01, -0.65]}
          scale={1.08}
        />
        <mesh
          name="Daisy005"
          castShadow
          receiveShadow
          geometry={nodes.Daisy005.geometry}
          material={nodes.Daisy005.material}
          position={[-0.09, -0.026, 0.2]}
          rotation={[-1.56, 0, -0.9]}
          scale={[1.08, 1.08, 1.08]}
        />
        <mesh
          name="Daisy006"
          castShadow
          receiveShadow
          geometry={nodes.Daisy006.geometry}
          material={nodes.Daisy006.material}
          position={[-0.13, -0.026, 0.18]}
          rotation={[-1.59, -0.01, -0.98]}
          scale={[0.96, 0.96, 0.96]}
        />
        <mesh
          name="Daisy007"
          castShadow
          receiveShadow
          geometry={nodes.Daisy007.geometry}
          material={nodes.Daisy007.material}
          position={[-0.15, -0.026, 0.15]}
          rotation={[-1.56, 0, -0.66]}
          scale={[0.75, 0.75, 0.75]}
        />
        <mesh
          name="Daisy008"
          castShadow
          receiveShadow
          geometry={nodes.Daisy008.geometry}
          material={nodes.Daisy008.material}
          position={[-0.26, -0.026, -0.06]}
          rotation={[-1.59, -0.03, -0.67]}
          scale={[0.75, 0.75, 0.75]}
        />
        <mesh
          name="Daisy009"
          castShadow
          receiveShadow
          geometry={nodes.Daisy009.geometry}
          material={nodes.Daisy009.material}
          position={[-0.28, -0.026, -0.04]}
          rotation={[-1.57, -0.02, -0.26]}
          scale={[1.02, 1.02, 1.02]}
        />
        <mesh
          name="Daisy010"
          castShadow
          receiveShadow
          geometry={nodes.Daisy010.geometry}
          material={nodes.Daisy010.material}
          position={[-0.3, -0.027, 0.13]}
          rotation={[-1.57, -0.03, -0.79]}
          scale={[0.47, 0.47, 0.47]}
        />
        <mesh
          name="Daisy011"
          castShadow
          receiveShadow
          geometry={nodes.Daisy011.geometry}
          material={nodes.Daisy011.material}
          position={[-0.29, -0.027, 0.1]}
          rotation={[-1.58, -0.05, -0.58]}
          scale={0.47}
        />
        <mesh
          name="Daisy012"
          castShadow
          receiveShadow
          geometry={nodes.Daisy012.geometry}
          material={nodes.Daisy012.material}
          position={[-0.31, -0.027, 0.11]}
          rotation={[-1.58, -0.06, -0.6]}
          scale={[0.42, 0.42, 0.42]}
        />
        <mesh
          name="Platform002"
          castShadow
          receiveShadow
          geometry={nodes.Platform002.geometry}
          material={materials.Platform}
          position={[0, -0.12, 0]}
          scale={1.08}
        />
      </group>
    </group>
  );
};

export default memo(GreenHill);
