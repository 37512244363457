import { useProgress } from '@react-three/drei';
import { useEffect, useState } from 'react';
import FriendsieBuilder from '../builder_components/FriendsieScene';
import SceneLoader from '../builder_components/SceneLoader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Builder = ({ signer, connectHandler, disconnectHandler }) => {
  return (
    <>
      {/* className={`z-40 h-100 w-100 overflow-hidden ${clicked ? 'o-fade-in' : 'opacity-0'}`} */}
      <div className="overflow-hidden h-screen w-screen touch-none">
        <SceneLoader />
        <FriendsieBuilder signer={signer} connectHandler={connectHandler} disconnectHandler={disconnectHandler} />
        <ToastContainer
          // autoClose={false}
          autoClose={5000}
        />
      </div>
    </>
  );
};

export default Builder;
