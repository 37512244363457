import React from 'react';
import classes from './RandomUndo.module.css';
import UndoIcon from '../../assets/undo_icon.png';
import RandomIcon from '../../assets/random_icon.png';
import { useSelector } from 'react-redux';

const RandomUndo = ({ previousFriendsieState, undo, randomFriendsie }) => {
  const disableUndoTimer = useSelector((state) => state.user.disableUndoTimer);

  return (
    <div className="mt-6 flex flew-row place-content-center items-center rounded-xl bg-opacity-75 bg-fbluetrait p-2 md:top-2 lg: inset-y-auto align-middle">
      <div
        className={`place-content-center items-center flex flex-col mb-0 rounded-full px-2 py-1 ${'border-transparent'}`}
        onClick={() => {
          undo();
        }}>
        <div className={`${previousFriendsieState && disableUndoTimer > 0 ? classes.scaleOnHover : ''} place-content-center items-center flex flex-col`}>
          <img
            className={`object-scale-down h-10 mb-1 place-content-center ${
              previousFriendsieState && disableUndoTimer > 0 ? 'cursor-pointer' : `cursor-default ${classes.grayScale}`
            }
             select-none`}
            alt="trait"
            src={UndoIcon}></img>
          {/* <button className="shadow-md py-1 w-16 block text-fgreen bg-fyellow rounded-full font-bold select-none text-center text-xs">
              {traitData.name}
            </button> */}
          <button className={`friendsie-pill${previousFriendsieState && disableUndoTimer > 0 ? '' : '-disabled'}`}>{`UNDO`}</button>
        </div>
      </div>
      <div
        className={` place-content-center items-center flex flex-col mb-0 rounded-full px-2 py-1 ${'border-transparent'}`}
        onClick={() => {
          randomFriendsie();
        }}>
        <div className={`${classes.scaleOnHover} place-content-center items-center flex flex-col`}>
          <img className={` object-scale-down h-10 mb-1 place-content-center cursor-pointer select-none`} alt="trait" src={RandomIcon}></img>
          {/* <button className="shadow-md py-1 w-16 block text-fgreen bg-fyellow rounded-full font-bold select-none text-center text-xs">
              {traitData.name}
            </button> */}
          <button className={`friendsie-pill`}>{`RANDOM`}</button>
        </div>
      </div>
    </div>
  );
};

export default RandomUndo;
