import React, { memo, useEffect, useMemo, useRef } from 'react';
import { useGLTF, useAnimations, useTexture, Bounds } from '@react-three/drei';
import * as THREE from 'three';
import { useLoader, useThree } from '@react-three/fiber';
// import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { RGBELoader } from 'three-stdlib';
import { Selection, Select, EffectComposer, SelectiveBloom, Noise, SSAO } from '@react-three/postprocessing';

const Pond = (props) => {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF('/models/world/Pond.glb');

  const envMapTexture = useLoader(RGBELoader, '/hill_1k.hdr');

  // console.log('envMapTexture: ', envMapTexture);

  // const envMapTexture = useLoader(
  //   RGBELoader,
  //   'https://market-assets.fra1.cdn.digitaloceanspaces.com/market-assets/hdris/empty-wharehouse/empty_warehouse_01_1k.hdr'
  // );

  // const envMapTexture = useTexture('/hill_1k.hdr');
  // const envMapTexture = THREE.RGBELoader('/hill_1k.hdr');

  const { gl } = useThree();

  useEffect(() => {
    // materials.someMaterial.color.set('#ff0000');
    // materials.someMaterial.transparent = true;
    // materials.someMaterial.opacity = 0.5;

    // const gen = new THREE.PMREMGenerator(gl);
    // gen.compileEquirectangularShader();
    // const hdrRenderTarget = gen.fromEquirectangular(envMapTexture);

    materials['Platform.002'].needsUpdate = true;
    // if (envMapTexture) {
    //   materials['Platform.002'].envMap = envMapTexture;
    //   materials['Platform.002'].envMap-encoding =
    // }

    // materials['Platform.002'].envMapIntensity = 1;

    // materials['Platform.002'].refractionRatio = 1;
    // materials['Platform.002'].color = new THREE.Color('blue');
    materials['Platform.002'].needsUpdate = true;
  }, [materials, envMapTexture, gl]);

  // console.log('pond material: ', materials['Platform.002']);
  return (
    <Selection>
      {/* <EffectComposer multisampling={0.5} autoClear={false}>
        <SelectiveBloom kernelSize={1} luminanceThreshold={0} intensity={3} luminanceSmoothing={0} />
      </EffectComposer> */}

      <Select enabled>
        <group ref={group} {...props} dispose={null} castShadow receiveShadow>
          <mesh
            name="Platform006"
            castShadow
            receiveShadow
            geometry={nodes.Platform006.geometry}
            // material={materials['Platform.002']}
            position={[0, -0.14, 0]}
            scale={[0.58, 0.11, 0.58]}>
            {/* <Clone object={materials['Platform.002']} inject={<meshStandardMaterial envMap={envMapTexture} />} /> */}
            {/* <primitive map={materials['Platform.002']} attach="material" /> */}
            {/* <meshStandardMaterial envMap={envMapTexture} attach="material" /> */}
            <meshStandardMaterial
              {...materials['Platform.002']}
              envMap={envMapTexture}
              envMapIntensity={1.5}
              roughness={1}
              metalness={1}
              envMap-encoding={THREE.LinearEncoding}
              envMap-mapping={THREE.EquirectangularReflectionMapping}
            />
          </mesh>

          {/* envMap={envMapTexture} */}
        </group>
      </Select>
    </Selection>
  );
};

export default Pond;

useGLTF.preload('/models/world/Pond.glb');
