import { useProgress } from '@react-three/drei';
import * as React from 'react';

// const defaultDataInterpolation = (p: number) => `Loading ${p.toFixed(2)}%`;
const defaultDataInterpolation = (p) => `Loading ${p.toFixed()}%`;

const SceneLoader = React.memo(({ dataInterpolation = defaultDataInterpolation, initialState = (active) => active }) => {
  const { active, progress, loaded } = useProgress();
  const progressRef = React.useRef(0);
  const rafRef = React.useRef(0);
  const progressSpanRef = React.useRef(null);
  const [shown, setShown] = React.useState(initialState(true));

  // React.useEffect(() => {
  //   let t;
  //   if (active !== shown) t = setTimeout(() => setShown(active), 300);
  //   return () => clearTimeout(t);
  // }, [shown, active]);

  const updateProgress = React.useCallback(() => {
    if (!progressSpanRef.current) return;
    progressRef.current += (progress - progressRef.current) / 2;
    if (progressRef.current > 0.95 * progress || progress === 100) progressRef.current = progress;
    progressSpanRef.current.innerText = dataInterpolation(progressRef.current);
    if (progressRef.current < progress) rafRef.current = requestAnimationFrame(updateProgress);
  }, [dataInterpolation, progress]);

  React.useEffect(() => {
    updateProgress();
    return () => cancelAnimationFrame(rafRef.current);
  }, [updateProgress]);

  return shown ? (
    <div
      className={`${
        loaded && progress === 100 ? 'hidden' : 'opacity-100'
      } w-full h-full absolute top-0 left-0 flex	flex-col items-center justify-center  bg-black text-center  transition-all duration-5000`}>
      <div>
        <div>
          <div
            className="w-full bg-white max-w-[50%] h-8 origin-left transition-transform"
            style={{
              transform: `scaleX(${progress / 100})`,
            }}></div>
          <span ref={progressSpanRef} className="relative inline-block text-center text-base whitespace-nowrap mt-4 text-white	" />
        </div>
      </div>
    </div>
  ) : null;
});

export default SceneLoader;
