import React, { useEffect, useState } from 'react';
import classes from './BuilderModal.module.css';
import FriendsWithYouCloud from '../../assets/images/FriendsWithYouCloud.png';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as TwitterIcon } from '../../assets/twitter-brands.svg'; //'./assets/twitter-brands.svg';
import { setBuildingFriendsieLoadingState, setFriendsieTokenId } from '../../state/user/userSlice';

const BuilderModal = ({ cancelBuild, closeBuilderModal, buildNowButton }) => {
  const friendsieTokenId = useSelector((state) => state.user.friendsieTokenId);
  const builtFriendsieImage = useSelector((state) => state.user.builtFriendsieImage);
  const buildButtonLoading = useSelector((state) => state.user.buildButtonLoading);

  const [cancelBuildTimer, setCancelBuildTimer] = useState(10);
  const dispatch = useDispatch();

  useEffect(() => {
    let cancelBuildInterval = setInterval(() => {
      if (cancelBuildTimer > 0) {
        // would be better to keep this in local state, but timer needs to be called from seeds/update easier to make it globally available
        // component wont retrigger if redux store value always set to the same amount (in this case, we're resetting it to 600 seconds everytime, so component doesnt re-render)
        setCancelBuildTimer(cancelBuildTimer - 1);
      } else {
        clearInterval(cancelBuildInterval);
      }
    }, 1000);
    return () => {
      clearInterval(cancelBuildInterval);
    };
  }, [cancelBuildTimer]);

  let tweetText = encodeURIComponent('I just minted my own customized fRiENDSiES! @fRiENDSiES_Ai @Friendswithyou');

  return (
    <div className={`${classes.mintingFriendsieOverlay} z-50  top-0 left-0  h-screen w-screen fixed flex	flex-col items-center justify-center`}>
      <div
        className={`${classes.mintingFriendsieModal} relative rounded-lg flex flex-col justify-center items-center place-items-center ${
          friendsieTokenId ? 'px-16 pt-16 pb-12' : 'px-20 pt-20 pb-16'
        } `}
      >
        {/* Only show close button on build complete */}
        {friendsieTokenId && (
          <button
            onClick={() => closeBuilderModal()}
            className="absolute -top-2 left-2 bg-red-500 px-2 py-1 font-FredokaOne text-white rounded-lg mt-4 hover:bg-red-400"
          >
            X
          </button>
        )}
        {/* No token, so build is still occurring */}
        {!friendsieTokenId ? (
          <>
            {/* <img draggable={false} src={FriendsWithYouCloud} alt="friendsie cloud" className="h-40 select-none" /> */}
            {builtFriendsieImage && (
              <>
                <p className="text-fbuilderGreen  font-FredokaOne">fRiENDSiE PREVIEW</p>
                <div className="overflow-hidden rounded-xl mb-4">
                  <img
                    draggable={false}
                    src={builtFriendsieImage ? builtFriendsieImage : null}
                    alt="friendsie cloud"
                    className={`${classes.friendsieSceneImage} select-none`}
                  />
                </div>
              </>
            )}
            <div className=" flex flex-col justify-center items-center place-items-center">
              <p className="text-fbuilderGreen font-FredokaOne text-2xl">BUILDING YOUR fRiENDSiE</p>
              <p className="text-fbuilderGreen  font-FredokaOne"> Please be patient while we get your fRiENDSiES heart beating </p>
              <div className="select-none  place-content-center  inset-x-0 mx-auto mt-4">
                <div className={`${classes.dotPulse} place-items-center`}></div>
              </div>
              <div className="flex flex-col">
                {/* Only show cancel button if we are not waiting on seeds/build  */}
                {!buildButtonLoading && (
                  <button className="bg-red-500 p-4 font-FredokaOne text-white rounded-xl mt-4 hover:bg-red-400 mr-1" onClick={() => cancelBuild()}>
                    {`CANCEL BUILD...${cancelBuildTimer}`}
                  </button>
                )}
                <button
                  className={`${classes.mintButton} flex flex-row font-FredokaOne text-white rounded-xl mt-4 ml-1  place-content-center `}
                  onClick={() => buildNowButton()}
                >
                  {buildButtonLoading && (
                    <svg className="animate-spin h-5 w-5 mr-3 text-white" viewBox="0 0 24 24">
                      {/* <circle className="opacity-25" cx="12" cy="12" r="10" stroke="text-white" stroke-width="4"></circle> */}
                      <path
                        className="opacity-75 text-white"
                        fill="#0dbd00"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  {`${buildButtonLoading ? 'BUILDING' : 'BUILD NOW'}`}
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            {/* 
            User has token, so they were successful. Landing here means they still have pending tokens, so
            send them back to builder on close of this modal
            */}
            <div className={`relative place-content-center items-center flex flex-col rounded-full align-middle`}>
              {builtFriendsieImage && (
                <div className="overflow-hidden rounded-xl mb-4">
                  <img
                    draggable={false}
                    src={builtFriendsieImage ? builtFriendsieImage : null}
                    alt="friendsie cloud"
                    className={`${classes.friendsieSceneImage} select-none`}
                  />
                </div>
              )}
              <p className="text-fbuilderGreen font-FredokaOne leading-5">
                {`fRiENDSiES token #${friendsieTokenId} is completed! It will be finalized on-chain`}{' '}
              </p>
              <p className="text-fbuilderGreen font-FredokaOne leading-5">{`and visible on OpenSea with the public reveal!`}</p>

              <p className="text-center text-fbuilderGreen font-Frankfurter align-middle mb-2 mt-4 text-xl">SHARE YOUR fRiENDSiE!</p>
              <div className={`${classes.tweetButton} rounded-full flex flex-row px-4 py-1 place-content-center items-center hover: cursor-pointer`}>
                <TwitterIcon className="h-8 w-8 mr-2 text-white  p-1 rounded-lg" />
                <a
                  className="text-white text-xl text-center font-Frankfurter"
                  href={`http://twitter.com/share?text=${tweetText}&url=https://friendsies.io/share/${friendsieTokenId}&hashtags=fRiENDSiES`}
                >
                  SHARE
                </a>
              </div>
              <div className={`mt-8`}>
                <button onClick={() => closeBuilderModal()} className={`${classes.mintButton} font-xl`}>
                  BUILD NEXT fRiENDSiE
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BuilderModal;
