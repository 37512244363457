import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { memo, useEffect, useRef } from 'react';
import * as THREE from 'three';

const FixedModel = ({
  friendsieState,
  downloadingModel,
  // setHeadAnimation
}) => {
  let group = useRef();
  // let traitData = getTraitData(friendsieState.index, 'Body');
  const modelData = useGLTF(friendsieState.asset_url);
  // setHeadAnimation(modelData.animations);
  // { ref, actions, names }
  // let animationData = useAnimations(modelData.animations, group);

  // useEffect(() => {
  //   // Reset and fade in animation after an index has been changed
  //   animationData.actions[animationData.names[0]].reset().fadeIn(0.5).play();
  //   // In the clean-up phase, fade it out
  //   console.log('animation updated');
  //   return () => animationData.actions[animationData.names[0]].fadeOut(0.5);
  // }, [animationData, friendsieState, modelData, traitData]);

  let mixer;
  if (modelData && modelData.animations.length && !downloadingModel) {
    mixer = new THREE.AnimationMixer(modelData.scene);
    modelData.animations.forEach((clip) => {
      const action = mixer.clipAction(clip);
      action.play();
    });
  }

  useFrame((state, delta) => {
    mixer?.update(delta);
  });

  return (
    <group ref={group} dispose={null}>
      <primitive object={modelData.scene} />
      {/* <primitive object={modelData.nodes.Root} /> */}
      {/* <skinnedMesh
        geometry={modelData.nodes[traitData.geometry].geometry}
        material={modelData.materials[traitData.material]}
        skeleton={modelData.nodes[traitData.geometry].skeleton}
      /> */}
    </group>
  );
};

export default FixedModel;
