import Head from './Head';
import Body from './Body';
import Accessory from './Accessory';
import Backpiece from './Backpiece';
import Sprout from './Sprout';
import Shoes from './Shoes';
import { forwardRef, memo, useEffect, useState } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { useGLTF, useProgress } from '@react-three/drei';
// import { AccessoryComponents, BackComponents, BodyComponents, FeetComponents, HeadComponents, LookupComponent, SproutComponents } from './sceneutils';
import * as THREE from 'three';
import FixedModel from './FixedModel';

const FriendiseModel = (
  {
    friendsieState,
    mockObject,
    downloadingModel,
    setEntireScene,
    // setHeadAnimation,
    // setSproutAnimation,
    // setBodyAnimation,
    // setAccessoryAnimation,
    // setShoeAnimation,
    // setBackpieceAnimation,
    // setRendererInfo,
    // traitCategory,
    // faceState,
    // accessoryState,
    // sproutState,
    // headState,
    // bodyState,
    // backState,
    // feetState,
  },
  ref
) => {
  const { active } = useProgress();
  const { gl, scene, camera } = useThree();

  useEffect(() => {
    setEntireScene(gl);
  }, [gl, setEntireScene]);

  // useFrame(() => {
  //   if (ref.current) {
  //     ref.current.quaternion.slerp(mockObject.quaternion, 1);
  //   }
  // });

  return (
    <group ref={ref} name="friendsieModel" visible={active ? false : true} castShadow receiveShadow>
      {friendsieState.fixedModel && (
        <FixedModel
          friendsieState={friendsieState.head}
          downloadingModel={downloadingModel}
          // setHeadAnimation={setHeadAnimation}
        />
      )}
      {!friendsieState.fixedModel && friendsieState.head && (
        <Head
          friendsieState={friendsieState.head}
          face={friendsieState.face}
          entireState={friendsieState}
          downloadingModel={downloadingModel}
          // setHeadAnimation={setHeadAnimation}
        />
      )}
      {!friendsieState.fixedModel && !friendsieState.headNoSprout && friendsieState.sprout && friendsieState.sprout.asset_url && (
        <Sprout
          friendsieState={friendsieState.sprout}
          headSize={friendsieState.headSize}
          downloadingModel={downloadingModel}
          // setSproutAnimation={setSproutAnimation}
        />
      )}
      {!friendsieState.fixedModel && friendsieState.body && (
        <Body
          friendsieState={friendsieState.body}
          downloadingModel={downloadingModel}
          // setBodyAnimation={setBodyAnimation}
        />
      )}
      {!friendsieState.fixedModel && friendsieState.accessory && friendsieState.accessory.asset_url && (
        <Accessory
          friendsieState={friendsieState.accessory}
          downloadingModel={downloadingModel}
          // setAccessoryAnimation={setAccessoryAnimation}
        />
      )}
      {!friendsieState.fixedModel && friendsieState.backpiece && friendsieState.backpiece.asset_url && (
        <Backpiece
          friendsieState={friendsieState.backpiece}
          downloadingModel={downloadingModel}
          // setBackpieceAnimation={setBackpieceAnimation}
        />
      )}
      {!friendsieState.fixedModel && friendsieState.shoe && friendsieState.shoe.asset_url && (
        <Shoes
          friendsieState={friendsieState.shoe}
          downloadingModel={downloadingModel}
          // setShoeAnimation={setShoeAnimation}
        />
      )}
    </group>
  );
};

export default memo(forwardRef(FriendiseModel));
