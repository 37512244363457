// import { useNavigate } from "react-router-dom";
import friendsiesVideo from '../assets/friendsies_christies.mp4';
import appVideo from '../assets/cloud_app.mp4';
// import cloudVideo from '../assets/SuperGoldenCloudKey.mp4';

function Main(props) {
  // const navigate = useNavigate()

  const downloadAR = () => {
    window.location.href = '57049264-90f2-4f86-8568-a0ee88e0fd94.usdz';
  };

  // const navMint = () => {
  //   navigate("/mint")
  // }

  const navChristies = () => {
    window.location.href = 'https://www.christies.com/auctions/christies-x-opensea';
  };

  const navOpenSea = () => {
    window.location.href = 'https://opensea.io/collection/fwy-littlecloudpower';
  };

  // const navOpenSeaFriendsies = () => {
  //   window.location.href = "https://opensea.io/collection/friendsies"
  // }

  return (
    <div>
      <div className="container mx-auto px-2 lg:px-40">
        <div className="">
          <img src="/images/friendsies_header.png" className="mx-auto" alt="header" />
        </div>
        <div className="grid xl:grid-cols-2 mb-16">
          <div className="justify-self-center">
            <video className="w-90% h-90% lg:max-w-lg max-w-md" id="friendsies-vid" width="320" height="240" autoPlay loop muted playsInline>
              <source src={friendsiesVideo} type="video/mp4" />
            </video>
          </div>

          <div className="mt-8 mx-8 lg:text-xl">
            <h2 className="mb-4 text-gray-500 lg:text-5xl text-2xl font-bold">Welcome to fRiENDSiES</h2>
            <p>fRiENDSiES is a Web3 community of kindness, created by world-renowned contemporary artists FriendsWithYou.</p>
            <p className="mt-4">
              FriendsWithYou have developed a unique character builder, loaded with hundreds of pre-designed 3D assets, offering 10,000 lucky collectors the
              opportunity to customize and cultivate a life-long digital companion of their very own, through their immutable launch on-chain.
            </p>
            <p className="mt-4">
              Through tokenomics, the fRiENDSiES ecosystem will be powered by love, good vibes, and friendship. We invite you to join us on this journey as we
              build a better world where generosity, care and altruism are rewarded.
            </p>
          </div>
        </div>

        <div className="mt-16 grid xl:grid-cols-2 lg:text-xl">
          <div className="mx-8 order-last xl:order-first">
            <img src="/images/Christies.png" className="lg:w-5/6 mx-auto my-8" alt="christies" />
            <p>
              The road to 10,000 fRiENDSiES has already begun with the auction of fRiENDSiES 00001 closing with a hammer price of $425,000. The collector
              received a special bronze statue to commemorate this sale, marking the first time a bronze sculpture was auctioned and sold on the blockchain
              through Christie's.
            </p>
            <ul className="mt-8 block xl:hidden text-center">
              <li className="text-l font-semibold">"fRiENDSiES 00001” (1/1), 2021</li>
              <li className="text-l text-gray-500">Cast Bronze, Urethane Finish</li>
              <li className="text-l text-gray-500">
                36&frac34;”
                <span className="mx-2 text-sm">x</span>
                22&frac34;”
                <span className="mx-2 text-sm">x</span>
                50”
              </li>
            </ul>
            <button className="rounded-lg mt-8 bg-gray-800 w-full lg:text-3xl text-white text-xl py-2 font-bold" onClick={navChristies}>
              Christie's
            </button>
            <button className="rounded-lg mt-8 bg-gray-800 w-full lg:text-3xl text-white text-xl py-2 font-bold" onClick={navOpenSea}>
              OpenSea
            </button>
          </div>
          <div>
            <img src="/images/christies_statue.png" className="mx-auto lg:w-3/4 lg:h-3/4 my-8" alt="christies statue" />
            <ul className="mt-8 hidden xl:block text-center">
              <li className="text-xl font-semibold">"fRiENDSiES 00001” (1/1), 2021</li>
              <li className="text-l text-gray-500">Cast Bronze, Urethane Finish</li>
              <li className="text-l text-gray-500">
                36&frac34;”
                <span className="mx-2 text-sm">x</span>
                22&frac34;”
                <span className="mx-2 text-sm">x</span>
                50”
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="bg-gray-100 mt-10 lg:py-24 py-12 w-screen">
        <div className="container mx-auto px-2 lg:px-40">
          <div className="mx-8 grid lg:grid-cols-2 xl:grid-cols-3">
            <div className="order-last xl:px-4 px-6 xl:order-first">
              <h3 className="mx-auto lg:mt-8 text-gray-500 lg:mt-8 lg:text-2xl text-xl font-bold">Little Cloud Boy in AR</h3>
              <p className="lg:text-xl my-4">
                fRiENDSiES is your personal AI, AR friend of the future. Place Little Cloud boy anywhere, take a picture, make a friend. We are just getting
                started with Little Cloud Boy in AR, but we are excited to show you a first look.
              </p>
              <p className="lg:text-xl hidden lg:block my-4">Visit us again on your mobile phone to view Little Cloud Boy wherever you are.</p>
              <button className="lg:hidden rounded-lg bg-gray-800 w-full text-white text-xl px-4 py-2 font-bold" onClick={downloadAR}>
                Launch AR
              </button>
              {/* TODO: Do we display? Or just a launch AR button on mobile */}
            </div>
            <iframe
              id="57049264-90f2-4f86-8568-a0ee88e0fd94"
              src="https://app.vectary.com/viewer/v1/?model=57049264-90f2-4f86-8568-a0ee88e0fd94&env=studio3"
              frameBorder="0"
              width="80%"
              height="360"
              title="ar"
              className="hidden xl:block lg:mt-10 mt-4 justify-self-center"></iframe>
            <video
              className="order-first justify-self-center my-8 w-auto xl:order-last"
              id="app-vid"
              width="320"
              height="240"
              autoPlay
              loop
              muted
              playsInline
              controls>
              <source src={appVideo} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-6 lg:px-40"></div>

      <div className="container mx-auto px-6 lg:px-40">
        {/* <div className="mt-32 grid xl:grid-cols-2">
          <video className="justify-self-center w-full lg:max-w-lg max-w-md" id="cloud-vid" width="320" height="240" autoPlay loop muted playsInline>
            <source src={cloudVideo} type="video/mp4" />
          </video>
          <div className="justify-self-center mx-6 lg:mt-16">
            <h3 className="mb-4 mt-8 text-gray-500 lg:text-4xl text-xl font-bold">Super Golden Cloud Key</h3>
            <p className="lg:text-xl lg:mt-2">
              Super Golden Cloud Key is your entrance to all FriendsWithYou projects. We will make 1111 available
              starting Nov. 28th 2021 at 8 PM EST. They will be available first to our Golden Cloud Club members,
              collectors of our original Nifty Gateway collab, our best friends, and 1st supporters.
            </p>
            <div className="mt-6 lg:mt-8">
              <button
                className="rounded-lg bg-gray-800 w-full lg:text-3xl text-white text-xl py-2 font-bold"
                onClick={navOpenSeaFriendsies}
              >
                OpenSea
              </button>
            </div>
          </div>
        </div> */}

        <div className="mt-24">
          <img src="/images/10k_friendsies_header.png" className="lg:w-2/3" alt="friendsies title" />

          <div className="">
            <p className="mt-6 lg:text-2xl">
              Starting March 26th, 2022 we will finish the full launch of the 10,000 item collection with several key dates that are important to our community.
            </p>

            <h3 className="text-gray-500 lg:text-5xl text-2xl font-bold mt-12">Christie's Auction on OpenSea</h3>
            <p className="mt-2">
              <strong>Saturday, Mar. 26th | 12 PM EST &nbsp;to&nbsp; Saturday, Apr. 2nd | 12 AM EST</strong>
            </p>
            <p className="mt-6">
              In this historical sale, FriendsWithYou will be auctioning off 9 mint passes granting exclusive early access to the fRiENDSiES NFT character
              builder. Each lot will provide collectors, in sequential order, access to the NFT builder before it opens to the public. Design assets in the
              engine are available with varying rarities. The sooner you enter the builder, the greater the chance you can build the fRiENDSiE of your dreams.
            </p>
            <p className="mt-6">
              Once each of the 9 lucky collectors design and mint their personal fRiENDSiE, the artists will build and gift the collector with a 4' bronze
              sculpture (size may vary depending on final design choice) of their own design. This is the first time in art history a collector can work with an
              artist to design, mint and build a digital/physical artwork in this way.
            </p>
            <p className="mt-6">
              Starting on Monday, March 28th at 12 PM EST, the collector of fRiENDSiES 00002 will gain access to the builder. Then, 12 hours later, the
              collector of fRiENDSiES 00003 will gain access. This will continue for each of the first 9 fRiENDSiES following the schedule below:
            </p>
            <ul className="mt-6 list-disc list-outside ml-8">
              <li>fRiENDSiES 00002: Monday, March 28th, after 12pm EST</li>
              <li>fRiENDSiES 00003: Tuesday, March 29th, after 12am EST</li>
              <li>fRiENDSiES 00004: Tuesday, March 29th, after 12pm EST</li>
              <li>fRiENDSiES 00005: Wednesday, March 30th, after 12am EST</li>
              <li>fRiENDSiES 00006: Wednesday, March 30th, after 12pm EST</li>
              <li>fRiENDSiES 00007: Thursday, March 31st, after 12am EST</li>
              <li>fRiENDSiES 00008: Thursday, March 31st, after 12pm EST</li>
              <li>fRiENDSiES 00009: Friday, April 1st, after 12am EST</li>
              <li>fRiENDSiES 00010: Friday, April 1st, after 12pm EST</li>
            </ul>

            <h3 className="text-gray-500 lg:text-5xl text-2xl font-bold mt-12">The Ghost Cloud Friends Pre-mint</h3>
            <p className="mt-2">
              <strong>Saturday, April 2nd, 12am - 12pm EST</strong>
            </p>
            <p className="mt-6">
              The next stage in the fRiENDSiES journey is the pre-mint. We will award 64 fRiENDSiES to Ghost Cloud Friends and the development team. The
              remaining 50 will be awarded to community members and contest winners, who will have the opportunity to mint their fRiENDSiES for only 0.1 ETH.
              Further details for the contests can be found on our Discord. This is the start of a lifelong fRiEND-ship.
            </p>

            <h3 className="text-gray-500 lg:text-5xl text-2xl font-bold mt-12">The Public Dutch Auction Sale</h3>
            <p className="mt-2">
              <strong>Saturday, Apr. 2nd | 12 PM EST until SOLD OUT</strong>
            </p>
            <p className="mt-6">
              In the public sale, 8765 fRiENDSiES will be sold on friendsies.io. Every collector of a fRiENDSiES NFT will custom build their NFT. This process
              is different from other mints — so read carefully!!
            </p>
            <ul className="mt-6 list-disc list-outside ml-8">
              <li>The asking price will start at 3.33 ETH. Buyers can purchase multiple items, but must customize each in sequence.</li>
              <li>Every 10 minutes, we will decrease the price by 0.1 ETH. If a purchase is made before a decrease, we will extend the clock by 30 more seconds. The price will decrease until a final reserve price of 0.1 ETH.</li>
              <li>After the purchase, you will receive a queue position for entering the NFT builder which is attached to your fRiENDSiES NFT. The queue limits the number of simultaneous builders to 20 people.</li>
              <li>A live estimate of the wait time and the number of people in the queue will be visible.</li>
              <li>
                Once inside the builder, you can begin to customize your NFT. If you don't finish customizing your fRiENDSiE, you will re-enter the end of the
                builder queue. We will give every collector a minimum of 5 minutes to build their NFT. If there are no other builders in the queue, the build
                time will be extended.
              </li>
            </ul>

            <h3 className="text-gray-500 lg:text-5xl text-2xl font-bold mt-12">The Super Golden Cloud Key Mint</h3>
            <p className="mt-2">
              <strong>Sunday, Apr. 3rd | 12 PM EST until SOLD OUT</strong>
            </p>
            <p className="mt-6">
              The 1111 Super Golden Cloud Key holders will be able to create a fully customized 3D fRiENDSiE for no additional charge. Claiming your fRiENDSiE
              will begin on Sunday, April 3rd at 12 p.m. EST. Super Golden Cloud key members will claim on a first-come-first-serve basis. Remember, only 20
              people will have access to the NFT builder at the same time, see above for more information.
            </p>
            <ul className="mt-6 list-disc list-outside ml-8">
              <li>Super Golden Cloud Key holders will have access to exclusive rare traits, only available to them.</li>
              <li>
                Each key can only mint once. We will take a snapshot to prevent double minting. The date of the snapshot will be announced prior to the mint.
              </li>
              <li>Super Golden Cloud Key holders only have to pay gas to claim a fRiENDSiE.</li>
              <li>If you hold multiple Super Golden Cloud Keys, you will receive an equal amount of fRiENDSiEs.</li>
              <li>
                Super Golden Cloud Key Holders can expect special access to all future digital FriendsWithYou projects, token drops, and even physical goods.
              </li>
              <li>Unclaimed tokens will be randomly raffled away to the community by April 24th, 2022.</li>
            </ul>
          </div>

          <img src="/images/10000_Friendsies.jpg" className="mt-4 w-full mx-auto" alt="friendsies grid" />
        </div>

        <div className="mt-24">
          <h3 className="text-gray-500 lg:text-6xl text-3xl font-bold text-center">fRiENDSiES Forever</h3>
          <ul className="list-disc ml-10 lg:mt-4">
            <li className="text-fred text-4xl ml-4;">
              <span className="relative block -top-1 -left-1 text-black text-base">
                <span className="font-bold mr-1">Nov 28th, 2021</span>
                1111 Super Golden Cloud Keys will be claimable for: Golden Cloud Club Members, collectors of the Diplo X FriendsWithYou collab on Nifty Gateway,
                Best Friends, & the Cloud Counsel. This will be first come, first claimed.
              </span>
            </li>
            <li className="text-forange text-4xl ml-4;">
              <span className="relative block -top-1 -left-1 text-black text-base">
                <span className="font-bold mr-1">March 2022</span>
                10,000 fRiENDSiES will go on sale. Super Golden Cloud Key owners will be allowed to claim for free.
              </span>
            </li>
            <li className="text-fgreen text-4xl ml-4;">
              <span className="relative block -top-1 -left-1 text-black text-base">
                <span className="font-bold mr-1">2023</span> fRiENDSiES game ecosystem launch. Positive reinforcement reward game and collectables including
                open source designs from fRiENDSiES community.
              </span>
            </li>
            <li className="text-fturquoise text-4xl ml-4;">
              <span className="relative block -top-1 -left-1 text-black text-base">
                <span className="font-bold mr-1">2023 - 2025</span> AR solutions will be available and fRiENDSiES will accompanying you every where you would
                like. AI will learn about you while creating an emotional relationship with your companion, a true bond between human and a digital entity.
              </span>
            </li>
            <li className="text-fblue text-4xl ml-4;">
              <span className="relative block -top-1 -left-1 text-black text-base">
                Till the end fRiENDSiES will grow and learn with you as we step into the future. Your fRiENDSiES will guide you and help you in your life. A
                fRiENDSiES is forever.
              </span>
            </li>
          </ul>
        </div>

        <div className="mt-24">
          <h3 className="text-gray-500 lg:text-6xl text-3xl font-bold">fRiENDSiES Community</h3>

          <p className="mt-6">
            FriendsWithYou has cultivated a community of positivity for over 20 years working together. Their mission statement is to spread joy, kindness,
            empathy and love throughout the world. They have achieved this through a variety of mediums: painting, sculpture, fine art editions, traditional
            media (television, animation) and most notably through large-scale immersive public experiences. They have created the hit animation series
            <a className="underline mx-1" href="https://www.netflix.com/title/80063599">
              True and the Rainbow Kingdom
            </a>
            on Netflix, participated in the
            <a className="underline mx-1" href="https://twitter.com/Friendswithyou/status/1463937201394184193?s=20">
              Macy’s Thanksgiving Day Parade
            </a>
            in 2018 featuring their iconic Little Cloud character, and have exhibited their artworks in museums and public venues worldwide.
          </p>

          <p className="mt-6">
            Now, through the power of Web3 technologies, they have developed a tool for collectors to join them on their ever-evolving journey. fRiENDSiES are a
            connective vehicle for the exploration of a better world, created with the intention of transcendence and nurturing care for each member of the
            community. They are an aid in the promotion of love, harmony and peace in our lives. Your fRiENDSiE will be your AR/AI friend to help guide you for
            life.
          </p>

          <p className="mt-6">
            The future of fRiENDSiES is a Tomogachi-like game that is play-to-earn. It rewards friendship communities by enhancing their fRiENDSiES’ value
            through interactions and good deeds. The FriendsWithYou team is building a digital landscape for your fRiENDSiE to play, socialize, share and grow.
            All you’ll need to participate is to mint a fRiENDSiE of your very own. Let’s play and make the world a better place at the same time!
          </p>

          <p className="mt-6">
            Visit{' '}
            <a className="underline mx-1" href="https://friendswithyou.com">
              friendswithyou.com
            </a>{' '}
            to learn more.
          </p>

          <p className="ml-1 mt-4">Sam&nbsp;&&nbsp;Tury</p>
          <p className="">
            <a className="underline mx-1" href="https://twitter.com/Friendswithyou">
              @FriendsWithYou
            </a>
          </p>

          <p className="mt-8">
            Built by{' '}
            <a href="https://twitter.com/devloper_xyz" className="underline">
              @devloper_xyz
            </a>
            .
          </p>

          <hr className="mt-32" />
        </div>
      </div>
    </div>
  );
}

export default Main;
