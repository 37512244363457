import { memo, Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { Stats, PresentationControls, useProgress, OrbitControls, Loader, Environment, useTexture, useGLTF } from '@react-three/drei';
import * as THREE from 'three';
import FriendiseModel from '../builder_components/Friendsies/FriendiseModel';
import World from '../builder_components/Environment/World';

import { useParams, useSearchParams } from 'react-router-dom';

import { createModelFromShare } from '../api/api';
import { useSelector } from 'react-redux';

import classes from '../builder_components/FriendsieScene.module.css';
import LoadingCloudModel from '../builder_components/Friendsies/LoadingCloudModel';

const mockObject = new THREE.Object3D();

const OpenSea = () => {
  const lookAtPos = new THREE.Vector3(0, 0.05, 0);

  // builder states
  const [friendsieState, setFriendsieState] = useState(null);

  const [downloadingModel, setDownloadingModel] = useState(false);
  const [entireScene, setEntireScene] = useState(null);

  // threejs scene states
  const { loaded } = useProgress();

  const { seedId } = useParams();

  // Inital app load data. if user is in preview, call this, and don't load data from websocket
  useEffect(() => {
    createModelFromShare(seedId, setFriendsieState);

    // eslint-disable-next-line
  }, []);

  // model ref for download
  const modelDownloadMesh = useRef();

  return (
    <div className="overflow-hidden h-screen w-screen touch-none">
      <div className={`${loaded ? 'opacity-100' : 'opacity-0'}  overflow-hidden touch-none ${classes.builderCanvas}`}>
        <Canvas
          shadows
          flat
          linear
          mode="concurrent"
          camera={{ near: 0.01, far: 100 }}
          gl={{
            powerPreference: 'high-performance',
            // preserveDrawingBuffer: true,
            // alpha: true,
            antialias: true,
            // outputEncoding: THREE.sRGBEncoding,
            physicallyCorrectLights: true,
          }}
          onCreated={({ camera }) => {
            camera.position.x = 0;
            camera.position.y = 0.05;
            camera.position.z = 0.65;
            camera.lookAt(lookAtPos);
            camera.updateProjectionMatrix();
            // camera.fov =
          }}>
          <Suspense fallback={null}>
            <World />
            <Environment
              files={`/envMaps/empty_warehouse_01_1k.hdr`}

              // preset={envMapState}
            />
            {/* <Fireflies /> */}
            <LoadingCloudModel />
          </Suspense>
          <fog attach="fog" args={[new THREE.Color(0xffffff), 1, 7]} />
          <Suspense fallback={null}>
            <PresentationControls global polar={[0, 0]} speed={3}>
              {friendsieState && (
                <FriendiseModel
                  friendsieState={friendsieState}
                  mockObject={mockObject}
                  downloadingModel={downloadingModel}
                  setEntireScene={setEntireScene}
                  // setRendererInfo={setRendererInfo}
                  ref={modelDownloadMesh}
                />
              )}
            </PresentationControls>
          </Suspense>
        </Canvas>
      </div>
    </div>
  );
};

export default OpenSea;
